import React, { useEffect, useRef } from 'react'
import p5 from 'p5';
import sketch from "./sketch";
import styles from './styles.module.css'

const EmojiAnimation = () => {
    const wrapper = useRef();

    useEffect(() => {
        new p5(sketch, wrapper.current)
    }, [wrapper]);

    return <div className={styles.emoji_animation} ref={wrapper} />
};

export default React.memo(EmojiAnimation)
