import React, { useRef } from 'react';
import styles from './styles.module.css'
import heart from '../images/heart.png';
import clapping from '../images/clapping.png';
import clappingDark from '../images/clapping-dark.png';
import clappingWhite from '../images/clapping-white.png';
import celebrate from '../images/celebrate.png';
import EmojiButton from './emoji-button';
import { shallowEqual, useSelector } from 'react-redux';

const Emojis = () => {
    const images = useRef({
        heart: [{ name: "heart", src: heart }],
        celebrate: [{ name: "celebrate", src: celebrate }],
        clapping: [
            { name: "clapping", src: clapping },
            { name: "clapping_dark", src: clappingDark },
            { name: "clapping_white", src: clappingWhite }
        ]
    })
    const { emojis } = useSelector(({ event }) => ({ emojis: event.emojis }), shallowEqual);

    return (
        <div className={styles.emojis} tabIndex="19">
            {emojis.map((emoji) => {
                return <EmojiButton key={emoji.name} images={images.current[emoji.name]} {...emoji} />
            })}
        </div>
    )
}

export default React.memo(Emojis);

