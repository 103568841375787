import React from 'react';
import styles from './styles.module.css';

const PostCard = ({ username, caption, media_url }) => {
    return (
        <div className={styles.post_card}>
            <img className={styles.media} alt="" aria-label="media" src={media_url} />
            <p className={styles.caption}>{caption}</p>
        </div>
    )
}

export default React.memo(PostCard)