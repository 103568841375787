import React from 'react';
import styles from './styles.module.css'
import Icon from '../icon';

const Viewers = () => {
    return (
        <div className={styles.viewers} tabIndex="18">
            <Icon className={styles.icon} icon="viewers" /> 3158 viewers
        </div>
    )
}

export default React.memo(Viewers);
