import React from 'react';
import styles from './styles.module.css'

const Footer = () => {

    return (
        <div className={styles.footer}>
            <p className={styles.copyright} aria-label="copy right Dominican University of California 2022" tabIndex="33">© Dominican University of California 2022</p>
        </div>
    )
}


export default React.memo(Footer);