import agendas from './agendas.json'

const agendasReducer = (state = { current: '0', list: agendas }, { type, payload }) => {
    switch (type) {
        case "UPDATE-AGENDA": {
            return changeCurrentAgenda(state, payload, state.list)
        }
        default: {
            return changeCurrentAgenda(state, state.current, state.list)
        }
    }
}

const changeCurrentAgenda = (state, current, list) => {
    let foundCurrent = false
    return Object.assign({}, state, {
        current,
        list: list.map((agenda) => {
            if (agenda.id !== current && !foundCurrent) {
                return Object.assign(agenda, { status: 'previous' })
            }
            if (agenda.id === current && !foundCurrent) {
                foundCurrent = true
                return Object.assign(agenda, { status: 'current' })
            }
            return Object.assign(agenda, { status: 'next' })
        })
    })
}

export default agendasReducer;