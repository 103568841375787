import './index.css'
import React from 'react'
import store from "./store";
import ReactDOM from 'react-dom'
import LandingPage from './components/landing-page';
import { Provider } from 'react-redux';

const App = () => {
    return (
        <Provider store={store}>
            <LandingPage />
        </Provider>
    )
}

ReactDOM.render(<App />, document.getElementById('root'))
