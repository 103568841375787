import timeTracks from './time-tracks.json'

const event = {
    viewers: 0,
    time_tracks: timeTracks,
    emojis: [
        { "name": "heart", "count": 2355, "formatted": "2.4K" },
        { "name": "celebrate", "count": 1321, "formatted": "1.3K" },
        { "name": "clapping", "count": 1321, "formatted": "1.3K" }
    ]
}

const emojis = JSON.parse(localStorage.getItem('dominican_emojis'))
if (emojis) event.emojis = emojis;

const eventsReducer = (state = event, { type, payload }) => {
    switch (type) {
        case 'UPDATE-EMOJI': {
            return Object.assign({}, state, { emojis: updateEmoji(payload, state.emojis) })
        }
        default: {
            return state
        }
    }
}

const updateEmoji = ({ name, count }, emojis) => {
    emojis = emojis.map((emoji) => {
        if (name === emoji.name) {
            return { name, count, formatted: formatNumber(count) }
        } else {
            return emoji
        }
    })
    localStorage.setItem("dominican_emojis", JSON.stringify(emojis));
    return emojis
}

const formatNumber = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

export default eventsReducer;