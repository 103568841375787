import React from 'react';
import styles from './styles.module.css';
import Comment from './comment';
import comments from './comments.json';

const Comments = () => {
    return (
        <div className={`${styles.comments} ${styles.public}`}>
            <p className={styles.title} tabIndex="24">Message Board</p>
            <div className={styles.comments_list} tabIndex="27">
                {comments.map((comment, i) => <Comment key={i} {...comment} />)}
            </div>
        </div>
    )
}

export default React.memo(Comments);