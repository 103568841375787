import React from 'react';
import styles from './styles.module.css';
import Icon from '../../../icon'

const Socials = () => {
    return (
        <div className={styles.socials}>
            <button className={styles.social_icon} tabIndex="5" onClick={() => window.open('https://www.facebook.com/DominicanUniversityofCalifornia/', '_blank')}><Icon icon="fb" /></button>
            <button className={styles.social_icon} tabIndex="6" onClick={() => window.open('https://twitter.com/DominicanCANews', '_blank')}><Icon icon="twitter" /></button>
            <button className={styles.social_icon} tabIndex="7" onClick={() => window.open('https://www.instagram.com/dominicanuniversityca/?hl=en', '_blank')}><Icon icon="insta" /></button>
        </div>
    )
}

export default React.memo(Socials)