import React, { useRef } from 'react';
import styles from './styles.module.css'
// import Vimeo from '@vimeo/player'
// import moment from 'moment'
// import useCheckTime from './useCheckTime'
import EmojiAnimation from '../Emoji/emoji-animation'

const LiveVideo = () => {
    const iframe = useRef()
    // const checkTime = useCheckTime()

    // useEffect(() => {
    //     let previousTimeStamp = 0
    //     const player = new Vimeo(iframe.current)
    //     player.play()
    //     player.setVolume(0.7)
    //     const preCheckTime = ({ seconds }) => {
    //         const currentTimeStamp = Math.round(moment.duration(seconds, 'seconds').asSeconds())
    //         if (currentTimeStamp !== previousTimeStamp) {
    //             checkTime(currentTimeStamp)
    //             previousTimeStamp = currentTimeStamp
    //         }
    //     }

    //     player.on('timeupdate', preCheckTime)

    //     return () => player.off('timeupdate', preCheckTime)
    // }, [checkTime])


    return <div className={styles.live_video} tabIndex="23">
        <iframe
            ref={iframe}
            className={styles.iframe}
            title="live stream"
            src="https://player.vimeo.com/video/709664631"
            frameBorder="0"
            allow="autoplay"
            allowFullScreen={true}
        />
        <EmojiAnimation />
    </div>
}

export default React.memo(LiveVideo)