import Particle from './particle'
import clappingPNG from '../images/clapping.png'
import clappingDarkPNG from '../images/clapping-dark.png'
import clappingWhitePNG from '../images/clapping-white.png'
import heartPNG from '../images/heart.png'
import celebratePNG from '../images/celebrate.png'

const Sketch = (p) => {
    // A list for all of our particles
    let width, height
    let particles = []
    const emojis = {}
    p.preload = () => {
        emojis.clapping = p.loadImage(clappingPNG)
        emojis.clapping_dark = p.loadImage(clappingDarkPNG)
        emojis.clapping_white = p.loadImage(clappingWhitePNG)
        emojis.heart = p.loadImage(heartPNG)
        emojis.celebrate = p.loadImage(celebratePNG)
    }

    p.setup = () => {
        const setSize = () => {
            width = window.innerWidth
            height = window.innerHeight
            p.createCanvas(width, height)
        }
        window.addEventListener('orientationchange', setSize)
        window.addEventListener('resize', setSize)
        setSize()
    };

    p.draw = () => {
        p.clear()
        if (particles.length < 1) {
            p.noLoop()
        } else {
            let gravity = p.createVector(0, 0.5);
            // Look at all particles
            for (let i = particles.length - 1; i >= 0; i--) {
                particles[i].applyForce(gravity);
                particles[i].update();
                particles[i].display();
                if (particles[i].done()) {
                    particles.splice(i, 1);
                }
            }
        }
    };

    window.addEventListener('emoji-clicked', (e) => {
        const countParticles = p.random(10, 20);
        let i = 0;
        const x = p.random(width);
        const y = p.random(height / 2)
        const size = {
            width: ((width * 2) / 100) >= 20 ? 20 : ((width * 2) / 100),
            height: ((height * 4) / 100) >= 40 ? 40 : ((height * 4) / 100)
        }
        while (i < countParticles) {
            let sz = p.random(size.width, size.height);
            particles.push(new Particle(x, y, sz, emojis[e.detail], p));
            i++;
        }

        if (particles.length <= countParticles + 1) {
            p.loop()
        }
    })
}

export default Sketch;