import React from "react"

const Emblem = ({ className }) => {
	return (
		<svg className={className} viewBox="0 0 260 260" fill="none" >

			<g opacity="0.3">
				<path fill="#FFFFFF" d="M190.8,189l-4.1-1.7c-1.6-0.6-3.1-0.4-4.2,0c0.2-0.6,0.5-1.3,0.7-2.4c0.1-0.4,0.1-0.8,0.1-1.2
		c0-2.2-1.5-3.6-1.5-3.6c-0.2-0.2-0.5-0.2-0.7-0.1c-0.2,0.1-0.3,0.4-0.2,0.6c0,0,0.5,1.4,0.5,2.3c0,0.6-2.4,2.8-5.1,3.9
		c-2.3,1-6.1,0.3-7.5,0l-0.2,0c-0.8-0.1-2.6-0.5-3.9,0.4c-0.5,0.4-0.9,0.9-1,1.7l-0.2,0l0.4,4.9c-0.3,0.1-0.7,0.1-1,0.2
		c-2.7,0.6-6.5,1.4-11.3,2.7c-3.2,0.9-14.3,1.8-20.8,1.7c-7.9-0.1-21.2-2.2-26.9-3.6l0.5-4.3c0.3-0.5,0.3-1,0.2-1.4
		c-0.4-0.9-1.6-1.2-2.9-1.6l-0.2,0c-0.8-0.2-1.7-0.1-2.8,0c-1.9,0.2-4.3,0.4-7.2-0.6c-4.3-1.5-4.3-4.4-4.3-7.6
		c0-0.2-0.1-0.4-0.4-0.5c-0.2-0.1-0.5,0-0.6,0.2c-0.1,0.1-1.8,2.1-1.8,4.4c0,0.4,0.1,0.8,0.2,1.3c0.5,1.7,1.5,3.5,2.3,4.6
		c-0.9-0.1-2.2-0.2-4.2-0.1c-3.1,0.1-3.6,0-3.7,0c0,0,0,0,0,0c-0.2-0.1-0.4-0.1-0.6,0.1c-0.1,0-0.1,0.1-0.1,0.2h0c0,0,0,0,0,0
		c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.1,0.2,0.2c0.4,0.3,3.1,2.2,7.5,4.1c4.3,1.8,9.2,2.2,10.7,2.2l0,0.1
		c0,0-0.4,1-0.4,1c-0.5,1.2-0.9,2.2-0.9,2.9c0,0.4,0.1,0.7,0.4,0.9c0.8,0.6,9.2,3.5,13.5,4.4c6.5,1.4,16.7,2.6,25.1,2.2
		c7.2-0.3,20-2,24.8-3.3l0.7-0.2c9.2-2.5,12.2-3.6,12.5-4.6c0-0.1,0-0.2,0-0.4c0-0.8-0.4-2.1-0.9-3.5c1.1-0.1,3.1-0.4,4.8-0.8
		c2.6-0.5,3.1-0.8,5.4-2.1c2.2-1.3,9-2.7,9.1-2.8c0.2,0,0.4-0.2,0.4-0.5c0,0,0,0,0-0.1C191.1,189.3,191,189.1,190.8,189L190.8,189z
		 M164.7,190.9c0.7,0.3,1.6,0.5,2.3,0.6c0.6,0.1,1.5,0.3,1.7,0.5c-0.1,0-0.2,0.2-0.2,0.2c-0.5,0.5-1.7,0.9-3.6,1.3L164.7,190.9
		L164.7,190.9z M99,192.1c0-0.2,0.2-0.4,0.3-0.4c0.2-0.1,0.8,0,1.3,0c0.8,0.1,1.7,0.2,2.5-0.1l-0.4,2.9c-3.2-0.9-3.6-2-3.7-2.3
		C99,192.3,99,192.1,99,192.1L99,192.1L99,192.1z M86.3,193.1c-2.4-1-4.3-2.1-5.6-2.8c0.5,0,1.1,0,1.9,0c3.8-0.1,5.2,0.3,5.2,0.3
		c0.2,0.1,0.5,0,0.6-0.2c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c0,0-2.2-2.9-2.9-5.3c-0.1-0.3-0.1-0.6-0.1-0.9
		c0-0.8,0.3-1.5,0.6-2.2c0.2,2.6,0.9,5.4,5,6.7c3.1,1.1,5.7,0.8,7.6,0.6c1-0.1,1.9-0.2,2.5,0l0.2,0c0.9,0.2,2.1,0.6,2.2,0.9
		c0.1,0.1-0.1,0.4-0.1,0.5c-0.5,0.8-1.4,0.7-2.7,0.6c-0.8-0.1-1.4-0.2-1.9,0.1c-0.5,0.2-0.8,0.7-0.8,1.2c-0.1,0.2-0.6,2-1,3.2
		C96,195.2,90.7,194.9,86.3,193.1L86.3,193.1z M171.2,199.3c-0.6,0.9-8.7,3-11.8,3.9l-0.7,0.2c-4.3,1.2-16.7,3-24.5,3.3
		c-8.2,0.4-18.4-0.8-24.8-2.2c-4.3-0.9-12.1-3.6-13-4.2c0,0,0,0,0-0.1c0-0.5,0.6-1.9,0.8-2.5l0.4-1.1c0,0,0.5-1.7,0.9-3
		c0.6,0.7,1.8,1.4,4.1,2c5.2,1.4,19.6,3.7,28,3.8c6.8,0.1,17.8-0.8,21.2-1.7c4.8-1.4,8.5-2.2,11.3-2.7c3.3-0.7,5.1-1.1,6.1-1.9
		c0.3,0.9,0.9,2.4,0.9,2.4c0,0.1,0.1,0.1,0.1,0.1c0.6,1.6,1.1,3,1.1,3.6C171.2,199.2,171.2,199.3,171.2,199.3L171.2,199.3z
		 M181.1,191.9c-2.3,1.3-2.7,1.5-5.1,2c-1.8,0.4-4,0.7-5,0.8c-0.4-1.1-0.9-2.1-1.2-2.7c0,0,0,0,0,0c0-0.1,0-0.2-0.1-0.3
		c-0.2-0.8-1.3-1-2.6-1.2c-1.4-0.3-2.5-0.5-2.5-1.1c0,0,0,0,0-0.1c0.1-0.6,0.3-0.9,0.6-1.2c0.8-0.6,2.4-0.3,3-0.2l0.2,0
		c1.7,0.3,5.6,1,8.2-0.1c1.9-0.8,4.7-2.7,5.5-4.2c0,0,0,0,0,0c0,0.3,0,0.6-0.1,0.9c-0.6,2.4-1.2,3.4-1.2,3.4
		c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0.1,0.3,0.2,0.4c0.2,0.2,0.4,0.2,0.7,0.1c0,0,2.4-1.3,4.6-0.5c0,0,1.3,0.6,2.5,1
		C186.6,189.9,182.7,190.9,181.1,191.9L181.1,191.9z"/>
				<polygon fill="#FFFFFF" points="103,196.9 101.2,199.1 100.9,196.2 99.7,195.9 100.2,200.7 101.1,200.9 104.2,197.2 103,196.9 	" />
				<polygon fill="#FFFFFF" points="105.2,198.5 106.5,198.9 106.8,197.9 104.3,197.3 103.2,201.5 105.6,202.2 105.9,201.3 104.6,200.9 
		104.7,200.2 106,200.5 106.3,199.5 105,199.2 105.2,198.5 	"/>
				<path fill="#FFFFFF" d="M110.2,200.1c0.2-0.9-0.3-1.5-1.2-1.7l-1.7-0.4l-1,4.3l1.1,0.3l0.4-1.7h0l0.7,1.9l1.4,0.3l-0.9-2.1
		C109.6,201.2,110,200.7,110.2,200.1L110.2,200.1z M108.1,200.3l-0.1,0l0.3-1.1l0.1,0c0.4,0.1,0.8,0.2,0.7,0.7
		C108.9,200.4,108.4,200.3,108.1,200.3L108.1,200.3z"/>
				<polygon fill="#FFFFFF" points="110.1,203.3 111.2,203.5 112.2,199.2 111,198.9 110.1,203.3 	" />
				<polygon fill="#FFFFFF" points="115.4,199.8 112.4,199.2 112.2,200.2 113.1,200.4 112.5,203.8 113.6,204 114.3,200.6 115.2,200.8 
		115.4,199.8 	"/>
				<path fill="#FFFFFF" d="M117,200.1l-2.4,4.1l1.2,0.2l0.4-0.7l1.6,0.2l0.2,0.8l1.2,0.2l-1-4.7L117,200.1L117,200.1z M116.7,202.9
		l0.7-1.3h0l0.3,1.5L116.7,202.9L116.7,202.9z"/>
				<path fill="#FFFFFF" d="M122.1,202.3l-0.3-0.1c-0.2-0.1-0.5-0.2-0.5-0.4c0-0.2,0.3-0.4,0.5-0.3c0.3,0,0.5,0.1,0.7,0.3l0.5-0.9
		c-0.4-0.2-0.9-0.4-1.4-0.4c-0.9,0-1.6,0.5-1.6,1.4c0,0.9,0.4,1.1,1.1,1.3c0.2,0.1,0.7,0.2,0.7,0.5c0,0.3-0.3,0.4-0.6,0.4
		c-0.4,0-0.7-0.2-1-0.5l-0.5,0.9c0.4,0.3,1,0.6,1.5,0.6c0.5,0,0.9-0.1,1.3-0.4c0.4-0.3,0.5-0.7,0.5-1.2
		C123.1,202.9,122.7,202.6,122.1,202.3L122.1,202.3z"/>
				<polygon fill="#FFFFFF" points="127,202 128.4,202.1 128.5,201.1 126,200.9 125.6,205.3 126.8,205.4 126.9,203.7 128.2,203.8 
		128.2,202.8 127,202.7 127,202 	"/>
				<path fill="#FFFFFF" d="M130.2,201.2l-1.8,4.4l1.2,0l0.3-0.8l1.6,0l0.3,0.8l1.2,0l-1.6-4.5L130.2,201.2L130.2,201.2z M130.3,204
		l0.5-1.4h0l0.4,1.4L130.3,204L130.3,204z"/>
				<polygon fill="#FFFFFF" points="137.6,200.8 136.1,200.9 135.6,202.1 134.8,201 133.4,201.2 135,203.1 133.4,205.6 134.8,205.5 
		135.7,204 136.7,205.3 138.1,205.2 136.3,203 137.6,200.8 	"/>
				<path fill="#FFFFFF" d="M141.5,200.4l-1.2,4.6l1.2-0.1l0.2-0.8l1.6-0.2l0.4,0.7l1.2-0.1l-2.1-4.2L141.5,200.4L141.5,200.4z M142,203.2
		l0.3-1.5h0l0.6,1.4L142,203.2L142,203.2z"/>
				<path fill="#FFFFFF" d="M148.2,201c-0.1-0.9-0.8-1.3-1.7-1.2l-1.7,0.2l0.6,4.4l1.1-0.1l-0.2-1.7h0l1.3,1.6l1.4-0.2l-1.6-1.6
		C148,202.2,148.3,201.6,148.2,201L148.2,201z M146.3,201.9l-0.1,0l-0.1-1.1l0.1,0c0.4,0,0.8,0,0.9,0.5
		C147.1,201.7,146.7,201.8,146.3,201.9L146.3,201.9z"/>
				<path fill="#FFFFFF" d="M150.2,199.3l-1.6,0.2l0.7,4.4l1.6-0.2c1.2-0.2,2.1-1.3,1.9-2.5C152.6,199.9,151.5,199.1,150.2,199.3
		L150.2,199.3z M150.5,202.8l-0.3,0l-0.4-2.5l0.3,0c0.7-0.1,1.3,0.2,1.4,1C151.7,202.2,151.2,202.7,150.5,202.8L150.5,202.8z"/>
				<polygon fill="#FFFFFF" points="154.7,202.1 154.6,201.3 155.8,201.1 155.7,200.1 154.4,200.4 154.2,199.6 155.6,199.4 155.4,198.4 
		152.9,198.9 153.8,203.3 156.2,202.8 156.1,201.8 154.7,202.1 	"/>
				<polygon fill="#FFFFFF" points="159.1,197.3 159.9,200 159.9,200 157.1,198 155.9,198.3 157.2,202.6 158.3,202.2 157.5,199.6 
		157.6,199.6 160.4,201.6 161.5,201.3 160.2,197 159.1,197.3 	"/>
				<path fill="#FFFFFF" d="M163.4,198l-0.3,0c-0.2,0-0.5,0-0.6-0.3c-0.1-0.2,0.2-0.4,0.4-0.5c0.3-0.1,0.6,0,0.8,0.1l0.2-1
		c-0.4-0.1-1-0.1-1.4,0c-0.9,0.2-1.3,1-1.1,1.8c0.2,0.8,0.7,0.9,1.5,0.9c0.3,0,0.7,0,0.8,0.3c0.1,0.3-0.2,0.5-0.4,0.6
		c-0.4,0.1-0.8,0-1.1-0.2l-0.2,1c0.5,0.2,1.1,0.2,1.6,0.1c0.4-0.1,0.8-0.4,1.1-0.7c0.3-0.4,0.3-0.8,0.1-1.3
		C164.6,198.2,164,198,163.4,198L163.4,198z"/>
				<path fill="#FFFFFF" d="M89.4,105c0,0.1,0,0.2,0,0.2c0,0.1,0,0.3,0.1,0.4c0,0,6,7.2,6.2,7.5c0,0.4,0,58.7,0,58.7
		c0,2.5,0.6,10.8,8.5,13.1c3.5,1,13.8,3.2,19.2,4.2c5.3,0.9,7.1,2.5,7.1,2.6c0.1,0.1,0.3,0.2,0.5,0.1c0,0,2.8-0.8,5.2-1.7
		c0.8-0.3,3.1-0.7,5.8-1.1c5.3-0.8,12.7-2,17-4.1c4.4-2.1,7.2-9.1,7.2-13c0,0-0.2-58.2-0.2-58.7c0.4-0.3,8.4-7.4,8.4-7.4
		c0.1-0.1,0.2-0.3,0.2-0.4c0-0.1,0-0.1,0-0.2c-0.1-0.2-0.3-0.4-0.5-0.4H89.9C89.7,104.7,89.5,104.8,89.4,105L89.4,105z M118.4,130.3
		c5-0.5,3.2-4.8,3.2-4.8s0.5-0.7,1.7,0.5c0.6,0.6-0.4,0.8,1.1,2.9c1.1,1.6-0.9,2.7-0.4,4.3c0.3,0.8,1.1,2.9,0.5,4.4
		c-1.1,2.7-4.2,3.8-5.5,2.3c-1,1.3-3.1,2.8-5.7,3c0.1,0.1,0.1,0.3,0.3,0.7c0.2,1,0,2,2.6,2.6c2.6,0.6,4.7-0.4,5.4-2
		c0.7-1.6-1.4-2.1-1.4-2.1s1.2-1.3,2.1-1.7c0.9-0.4,4.1-0.4,5.9,2.3c1.1,1.7,1.6,7.1-2.2,9.8c-3.8,2.7-9,2.6-12.9-0.1
		c-3.9-2.7-5-11.2-5-11.2l0.1,0c-1.8-1.5-3.1-3.9-3.4-7.4c-0.7-7.5,5.8-15,11.2-14.9c0,0-2,3.2-2.3,6.4
		C113.2,128.7,115.8,130.6,118.4,130.3L118.4,130.3z M105.1,159.3c0.2-5.3,2.5-8.4,4-10.6l1.1,1c0,0-1,0-1.3,2.6
		c-0.4,2.6,0,4.9,1.8,7.4c1.8,2.5,4.4,2,5.1-0.6c0.7-2.6-2.3-1.4-2.1-2c0.2-0.6,1.5-2,3.4-1.5c2,0.5,2,2.3,2,2.3s1.4-0.4,2.2,0.9
		c0.9,1.2-0.1,2.6-0.1,2.6s0,0.7,1,2.1c1,1.3,0.5,3.2-0.5,4.3c-2.2,2.4-2.1,1.4-3.8,2.2c-1.1,0.5-2.7,1.9-6.4,0.1
		C106.5,167.6,104.9,164.2,105.1,159.3L105.1,159.3z M120,169.5c0,0,0.6,1.5,1.8,1.8c1.2,0.4,3.5,0.5,5.9-1.7
		c2.3-2.2,2.7-4.4,3.1-6.6c0.4-2.2-3.4-6.5-3.4-6.5s1,0.6,2.8,1.6c1.8,1,1.6,1.4,2.2,1.4c0.6,0,1.5,1.3,2.5,3.3c1,2,1,4.3,0.7,7
		c-0.2,2.7-2.7,4.8-2.7,4.8s0,2-1,4.2c-1,2.2-3.8,3.3-6,3.6c-2.2,0.2-3.8-1.7-4.2-1.5c-0.4,0.2,0,1-1.2,0.4
		c-1.2-0.6-3.2-1.5-3.4-4.7C116.8,173.3,120,169.5,120,169.5L120,169.5z M129.2,122.8c-1.5-0.6-2.8-1.6-2.8-1.6s-2.4,0.9-4.5,0.2
		c-2.6-0.7-3.7-2.1-3.7-2.1l-1.2-1.5c0-0.4,1.2-1.2,2.8-3.2c1.6-2,3.4-2.9,5.9-2.9c2.5,0,4.2,1,4.2,1s0.6-1.2,3.9-2.8
		c3.3-1.6,7.9-3.6,13.1-1.2c3.3,1.5,4.6,4.1,5.1,5.9l-0.4,2.1l-1.4,0.4c-0.1-0.1-0.2-0.2-0.4-0.3l-0.4-0.3c-1-0.9-2.8-2.4-2.7-3.3
		c0.1-0.5,0.4-1,0.6-1.4c0.2-0.4,0.4-0.7,0.2-1c0-0.1-0.2-0.3-0.5-0.3c-0.7,0-1.3,0.5-2.3,1.3c-1,0.8-1.7,2.8-2,3.8
		c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.2,0,0.5,0.1,0.7c-0.1,0-0.1-0.1-0.2-0.1c-0.2,0-0.4,0.1-0.6,0.4c-0.1,0.1-0.1,0.2-0.1,0.4
		c-0.3-0.4-0.7-0.7-1.1-1.1l-0.8-0.5c-0.5-0.4-0.7-1.2-0.8-1.9c-0.1-0.5-0.2-0.9-0.5-1.2c-0.5-0.5-2.7-0.7-3.1-0.6l-0.9,0.4l0.9,0.6
		c0.1,0.1,0.2,0.4,0.3,0.6c0.1,0.4,0.3,0.9,0.6,1.2c0.1,0.2,0.1,1.9,0.2,2.9c0.1,1.8,0.1,2.4,0.3,2.8l-0.3,0.1
		C135.9,122.8,132.5,124.2,129.2,122.8L129.2,122.8z M139.3,129.1l-1.6-1.1c-0.5-0.3-0.9-0.6-1.1-0.8c0.3-0.1,1-0.1,2.5-0.2v0
		l7.4-0.1c0,0,0,0,0,0c3.8,0,6.6,0.1,8.3,0.2v0c0.9,0.1,1.3,0.1,1.5,0.2c-0.2,0.2-0.6,0.5-1.1,0.8l-1.6,1.1c-0.7,0.5-0.7,1-0.7,1.4
		c0,0,0,0,0,0l-0.1,0.3c-0.4,0.4-0.9,0.5-1.4,0.5c-0.5,0-1.1,0.1-1.4,0.5c-0.3,0.3-0.4,0.6-0.4,0.8c0,0.3,0.2,0.5,0.4,0.7l0.1,0.1
		c0,0.1,0.1,0.3,0.1,0.6c0,1.6-0.5,6.1-0.8,9.6c-0.4,3.5-0.7,6.9-0.7,8.3c0,0.1,0,0.2,0,0.3c0,0.2,0,0.4,0,0.6
		c0,3.9-0.9,18.4-0.9,18.5l0,0.2l0.1,0.1c0,0,1.7,1.2,1.7,2.8c0,0,0,0.1,0,0.1c-0.1,0.9-0.7,1.7-1.8,2.2l-0.2,0.1
		c0,0-0.3,2.9-0.4,3.2c-0.2,0.1-0.9,0.3-0.9,0.3s-0.2,0-0.2,0c0,0-0.6-0.2-0.8-0.3c0-0.3-0.4-3.2-0.4-3.2l-0.2-0.1
		c-1.1-0.5-1.7-1.3-1.8-2.2c0,0,0-0.1,0-0.1c0-1.6,1.7-2.8,1.7-2.8l0.1-0.1l0-0.2c0-0.2-0.9-14.6-0.9-18.5c0-0.2,0-0.4,0-0.6
		c0-0.1,0-0.2,0-0.3c0-1.4-0.3-4.7-0.7-8.3c-0.3-3.4-0.8-7.8-0.8-9.5c0-0.4,0-0.6,0.1-0.7c0,0,0.1-0.1,0.1-0.1
		c0.2-0.2,0.4-0.4,0.4-0.7c0-0.2-0.1-0.5-0.4-0.8c-0.4-0.4-0.9-0.5-1.4-0.5c-0.5,0-1-0.1-1.4-0.5l-0.1-0.3c0,0,0,0,0,0
		C140,130.1,140,129.6,139.3,129.1L139.3,129.1z M152.2,117.8l1.1-5.2c0-0.2,0-0.3,0-0.5c0-0.4-0.1-0.8-0.2-1.2
		c0.7,0.9,1.4,1.9,1.8,2.7c0.3,0.7,0.4,1.8,0.4,2.8c0,1.4-0.2,2.7-0.2,2.7l1,0.1c0,0,0.2-1.2,1.1-2.1c0.4-0.4,0.6-0.9,0.6-1.4
		c0.2,0.3,0.3,0.6,0.4,0.8c0,0,0,0.1,0,0.1c0,0.7-0.8,2.2-1.3,3.1l-0.5,1c-0.2,0.4-0.8,1.2-1.3,1.9l-1.1,1.5
		c-0.3,0.5-0.4,1.2-0.4,1.7c0,0.2,0,0.4,0,0.6c-1.3-0.1-3-0.1-5.4-0.1c-0.6,0-1.2,0-1.8,0c0,0,0,0,0,0c-3.1,0-5.3,0.1-6.9,0.1
		c0-0.1,0-0.2,0-0.2l0,0l0.2-0.1c-0.6-0.8-1.2-2.1-1.2-2.8c0-0.1,0-0.1,0-0.2c0.1-0.2,0.1-0.3,0.1-0.5c0-1-0.7-2-1.1-2.3
		c0-0.2-0.1-1.4-0.1-2.3c-0.1-2.6-0.2-3.3-0.6-3.6c-0.1-0.1-0.2-0.4-0.3-0.7c0-0.1-0.1-0.2-0.1-0.3c0.6,0.1,1.2,0.2,1.4,0.3
		c0,0,0,0,0,0c0,0.1,0.2,0.7,0.2,0.7c0.2,0.8,0.4,1.9,1.2,2.4l0.8,0.6c0.6,0.4,0.9,0.6,1,0.9c0.3,0.8,0.7,1.2,0.7,1.3l1,1l-0.1-0.6
		l1.2,0.9l0.4,0l1.4-1.6c0,0,0.2-0.3,0.5-0.6c0.2,0.4,0.5,0.9,0.5,0.9c0,0,0,0,0,0c0,0.1-0.1,0.5-0.2,0.8c-0.1,0.5-0.3,1-0.3,1.5
		c0,0.9,1.3,3,1.4,3.2c0.1,0.1,0.3,0.2,0.4,0.1c0.1-0.1,0.2-0.3,0.1-0.4c-0.5-0.8-1.3-2.4-1.3-2.9c0-0.4,0.1-0.9,0.3-1.4
		c0.2-0.6,0.3-0.9,0.2-1.2l-0.8-1.4l-0.5,0l-0.7,1c0,0-0.8,1-1.2,1.4c-0.2-0.2-1-0.7-1.4-1l0,0c0-0.2,0-0.4,0-0.6c0-0.1,0-0.3,0-0.4
		c0,0,0.1,0.1,0.1,0.1c0.3,0.4,0.6,0.4,0.7,0.3c0.3-0.1,0.3-0.3,0.3-0.5c0-0.2,0-0.3-0.1-0.6c0-0.3-0.1-0.8-0.1-1.2
		c0-0.2,0-0.3,0-0.5c0.2-1,1-2.7,1.7-3.3c0.2-0.2,0.5-0.5,0.8-0.7c-0.2,0.4-0.3,0.8-0.4,1.2c0,0.1,0,0.1,0,0.2c0,1.3,1.7,2.8,3,4
		l0.4,0.3c1.1,1,2.1,2.8,2.1,3.8c0,0.1,0,0.2,0,0.2c-0.2,1.3-0.2,2.7-0.2,2.8h1c0,0,0-1.4,0.2-2.6c0.1-0.7-0.1-1.7-0.6-2.6
		c0.1-0.3,0.2-0.8,0.4-1.4L152.2,117.8L152.2,117.8z"/>
				<path fill="#FFFFFF" d="M160.8,67.9c0.6,0.8,1.4,2,2.1,2c0.3,0,0.5-0.1,0.7-0.4c0.2-0.2,0.2-0.4,0.1-0.6c-0.3-0.9-3.1-1.5-3.2-1.5
		c0,0,0,0,0,0c0,0,0,0,0,0L160.8,67.9L160.8,67.9z"/>
				<path fill="#FFFFFF" d="M161.3,76.8c1-0.5,2.7-0.3,4.3,0.4c3.2,1.4,2.9,3.9,1.3,6.5c-2.5,4-12.3-0.2-15.2-1.7c-3-1.5-12-4.9-15-4.9
		c-2.9,0-16.2,2.7-20.1,2.2c-3.9-0.5-7.3-1.5-11-3.5l0.1-1c0,0-4.1-1.5-5.1-1.2c-0.3,0.1-1,0.5-1.5,0.7c-1.2,0.4-2.9,0.8-3.7,1.1
		c-1.2,0.5-6.1,1.8-6.1,1.8s-0.3,0.8,0.9,1.7c0.4,0.3,0.7,0.6,1,0.8c0,0,0,0.1-0.1,0.1c-0.1,0.1,0,0.3,0.1,0.4l0.2,0.1
		c0.5,0.2,1.3,0.7,3,0.8l2.7,0.1c1.1,1,2.1,1.9,2.1,1.9c0,0,0.7-0.6,0.7-0.6c0,0-0.5-0.7-0.9-1.3c0.6,0,1.1,0,1.3,0.1
		c0.2,0,0.3-0.1,0.4-0.2c0,0,0,0,0,0c0.6,0.1,2,0.4,3.6,0.9c2.2,0.7,3.2,3.4,3.4,5.2c0.2,1.7-0.2,4.7-0.2,4.7s0.1-0.5,0.2-1.2
		l-0.1,0c-3.4-0.6-8.7-2.3-10.9-2.7c-2.2-0.4-4.5-0.7-5.2-0.4c-0.6,0.4-2.2,1-1.6,2c0.6,1,0.9,2.1,1.7,2.1c0.9,0,1.1-0.7,2.1-0.7
		c1,0,3.2,0,4.5,0.4c1.4,0.4,2.7,0.6,2.7,0.6l0.1,0.1c-0.9,0-1.7,0-2.2-0.1C97,91.5,95,92,92.6,93c-2.5,1-0.5,2.9-0.5,2.9
		s2.9,1.2,4.4-0.2c1.5-1.5,5.7-0.7,7.4-0.7c1.7,0,7.4,0.7,8.3-0.2c1-1,0.6-1.5,0.6-1.5s2.6,0.5,3.8,0.7c1.2,0.2,4.4-1.2,6.4-2.5
		c2-1.2,11.1-6.1,13.5-5.4c2.5,0.7,7.4,4.9,11.3,7.6c3.9,2.7,6.9,0.3,9.3,0c2.4-0.2,3.9,1.2,4.4,2c0.5,0.7,1,2,0.7,2.7
		c-0.2,0.7,1,1.7,1,1.7l1.8-1.6c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.3,0,0.4-0.1c0.6-0.4,0.8-0.8,0.8-1.1c0-0.5-0.4-1.9-2.1-3.6
		c-0.7-0.7-1.1-1.5-1.5-2.3c-0.5-0.9-0.9-1.8-1.8-2.1c-0.7-0.2-1.8-0.2-2.8-0.2c-0.8,0-1.9,0-2.2-0.2c-0.5-0.2-1-1-1.9-2.2l-0.8-1.1
		c1.1,0.3,2.2,0.7,2.9,1c1.7,0.7,5.8,1.6,8.2,0.9c2.5-0.7,5.2-2.5,5.5-6.3c0.5-5.1-4.9-5.6-6.6-5.5C161.8,76,160.3,77.3,161.3,76.8
		L161.3,76.8z M98.7,76.4c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5C98.2,76.6,98.4,76.4,98.7,76.4
		L98.7,76.4z M98.5,80.2c0,0,0.1,0.2,0.2,0.4L98.5,80.2l-2.3-0.2c0,0,0,0,0,0c0.4,0,0.7-0.1,1.1-0.1C97.6,79.8,98.1,80,98.5,80.2
		L98.5,80.2z M94.7,80.5c-0.8-0.1-1.3-0.2-1.8-0.3c0.4,0,1,0,1.7,0c0.5,0,1-0.1,1.5-0.2c0,0,0,0,0,0c0.2,0.2,0.4,0.4,0.6,0.6
		L94.7,80.5L94.7,80.5z M98.6,80.2c0.3,0.1,0.5,0.2,0.5,0.3c0.1,0.1,0.2,0.1,0.3,0.1c-0.2,0-0.3,0-0.5,0
		C98.7,80.4,98.6,80.2,98.6,80.2L98.6,80.2z M165.4,97c-0.4-0.6-1.1-1.5-1.7-2.4c0,0,0.1,0.1,0.1,0.1
		C164.8,95.7,165.2,96.5,165.4,97L165.4,97z M155.8,90.2c0.5,0.2,1.5,0.3,2.6,0.3c0.9,0,2,0,2.5,0.2c0.4,0.1,0.8,0.8,1.1,1.5
		c0.2,0.5,0.5,1,0.9,1.5c-1-1.2-2-2.2-2.7-2.5c-1.3-0.4-4.6-0.3-5.5-0.3c-0.3-0.4-2.5-3.6-3.6-5.1c0.6,0.2,1.1,0.3,1.2,0.3
		c0.2,0.3,1.1,1.5,1.1,1.5C154.4,89,155,89.8,155.8,90.2L155.8,90.2z"/>
				<path fill="#FFFFFF" d="M148.6,99.4l0.5,0.3c0.3,0.2,0.6,0.2,0.9,0.3c0.5,0.1,1.1,0.3,1.7,1.1c0.1,0,0.2,0,0.2,0c0,0,1.4,0.9,4.6,1.1
		c1.5,0.1,2-0.1,3-1.3c0.5-0.6,0.8-1.1,0.6-1.5c-0.2-0.5-1.2-0.8-1.6-0.8c-1.2-0.2-5.1-0.5-6.7-0.5c-1.2,0-2.6,0.1-3.9,0.7L148,99
		C148.2,99.1,148.4,99.2,148.6,99.4C148.6,99.4,148.6,99.4,148.6,99.4L148.6,99.4z"/>
				<path fill="#FFFFFF" d="M140.1,98.6c-0.5,0.1-0.8,0.1-1,0.2c1,0.8,2.1,1.9,2.4,2.6c0.5,0.3,1.4,0.3,3,0.3l5.4,0.3
		c1.4,0.1,1.6-0.1,1.6-0.1c0-0.1-0.1-0.3-0.3-0.5c-0.5-0.7-0.8-0.8-1.3-0.9c-0.4-0.1-1-0.2-1.6-0.8
		C146.8,98.6,142.4,98.3,140.1,98.6L140.1,98.6z"/>
				<path fill="#FFFFFF" d="M138.2,98.9c-0.3-0.2-0.6-0.4-0.7-0.5c-0.8-0.4-2.5-0.3-3.7-0.2l-1,0c-0.5,0-1.2,0.3-1.7,0.5
		c0.2,0.2,0.3,0.5,0.4,0.7l0.4,0.8l0.8,0.9c0.4,0.4,0.6,0.6,0.7,0.9c1.1,0.4,3.5,0.6,7.5,0l0.1-0.1l0-0.1c-0.2-0.6-1.4-1.9-2.5-2.7
		C138.4,99,138.3,99,138.2,98.9L138.2,98.9z"/>
				<path fill="#FFFFFF" d="M130.9,99.6c-0.1-0.2-0.1-0.4-0.4-0.6c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.2-0.1-0.4-0.2c0,0-0.1,0-0.1,0
		c-0.8-0.4-4.2-0.4-5.7-0.3c-0.3,0-1,0.3-1.6,0.6c1.2,0.9,2.1,2,2.3,2.6c0.5,0.5,0.8,0.7,1.6,0.7l1.1,0.1c1.4,0.1,3.8,0.3,4.9-0.1
		c-0.1-0.1-0.2-0.1-0.3-0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0,0.2-0.1,0.2-0.1c0-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.2-0.3-0.3
		c0,0,0,0,0-0.1c0,0,0,0,0,0l-0.9-1l-0.3-0.7L130.9,99.6L130.9,99.6z M130.4,99C130.4,99,130.4,99,130.4,99
		C130.4,99,130.4,99,130.4,99L130.4,99z"/>
				<path fill="#FFFFFF" d="M119.9,98.4c-3.1-0.3-5.1,0.1-6.1,0.3c0.8,0.5,1.5,1.1,1.9,1.5l0.4,0.3c0.1,0.1,0.1,0.1,0.2,0.2l1,1.1
		c0.5,0.4,2.1,0.4,3.2,0.3l0.9,0l0.6,0c0.7,0,2.1,0.1,2.4-0.3c0-0.1-0.2-0.4-0.4-0.6c-0.4-0.6-1.1-1.3-2-1.9
		c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.2l0,0C121,98.7,120.4,98.5,119.9,98.4L119.9,98.4z"/>
				<path fill="#FFFFFF" d="M112.9,98.8c-0.6-0.3-1.1-0.6-1.7-0.7c0,0,0,0-0.1,0c-2.7-0.3-3.7,0-4.1,0.1c-0.8,0.4-1.2,0.7-1.4,0.9
		c0.2,2.6,1,2.8,1.7,2.8l1.4,0c2,0.1,5.3,0.2,6.3-0.1c0.5-0.1,0.8-0.4,0.8-0.6c0,0,0,0,0-0.1c0-0.1,0-0.2-0.1-0.2c0,0,0-0.1-0.1-0.1
		l0,0l-0.4-0.3c-0.5-0.4-1.4-1.1-2.3-1.7C113,98.9,112.9,98.9,112.9,98.8L112.9,98.8z"/>
				<path fill="#FFFFFF" d="M120.5,67.1c0.3-0.2,0.6-0.5,0.6-1.1c0-0.3-0.1-0.6-0.3-1c-0.2-0.3-0.5-0.4-0.7-0.5c0,0,0,0-0.1,0
		c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.1,0-0.2,0c-0.5,0-1,0.2-1.4,0.5c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.3-0.3,0.4
		c0,0.1-0.1,0.3-0.1,0.5c0,0.4,0.1,0.8,0.5,1.2C118.5,67.6,119.7,67.7,120.5,67.1L120.5,67.1z"/>
				<path fill="#FFFFFF" d="M123.5,68.8l0,0.2c0,0,0.9,0.4,2.2,0.6c1.9,0.4,5.4-1.1,6.4-1.5c1-0.4,4.5-2.5,8.6-2c2.6,0.3,8.3,2.8,10.2,2.8
		c1.8,0,3.6-0.2,4.5-1.2c1-1,5.2-0.4,4.2-1c-1-0.6-3.3-1.7-4.7-1.4c-1.4,0.4-2.3,3.6-8.7,0.9c-1.4-0.6-5-2.2-7-2.6
		c-2-0.4-6.1,2.4-8.5,2.6c-2.5,0.2-4.9-1.8-6.7-2.9l0,0C125,64.9,125.4,67.5,123.5,68.8L123.5,68.8z"/>
				<path fill="#FFFFFF" d="M88,73.2c0.1,0.1,0.2,0.1,0.3,0.1c0.3,0.5,0.7,1,1.2,1.2c0.3,0.1,1-0.1,1.5-0.1c1.3,0,1.9,1,2.4,1.5l2.3-0.8
		c0,0-0.6-0.7-1-1.9c-0.4-1.2,0.4-1.6,0.2-2.4c-0.3-1.4-1.6-2.7-1.6-4.7c0-0.4,0.1-0.7,0.2-0.8c0,0,0.1,0,0.1,0c0,0,0.2-0.1,0.2-0.1
		c0.7,0.2,1.8,0.6,1.8,0.6c0.9,0.4,2.8,0.1,3.1-0.3c0.1-0.1,0.1-0.3,0.1-0.4c-0.1-0.1-0.1-0.1-0.2-0.2l-0.1,0.3
		c-0.6-1.5-1.7-1.2-2.3-1.1c-1.6,0.3-2.1,0.5-2.7,0.8l-0.4-0.3c0,0-1.3,0.2-2.1,1.3c-0.2,0.3-0.6,0.1-0.8,0.3
		c-0.8,1-0.4,2.9-0.4,2.9s-0.5-0.6-1-1.6c-0.1-0.3,0.7-3.1,0.1-2.3c0.1-0.1,0.2-0.2,0.3-0.4c-0.2-0.1-0.4,0-0.5,0.2
		c-0.6,0.8-1.4,1.7-2.1,3.6c-0.2,0.6-0.3,1.1-0.3,1.5c0,1.2,0.7,2.1,1.5,2.9C88,73.1,88,73.2,88,73.2L88,73.2z M87.2,68.8
		c0.6-1.5,1.1-2.3,1.6-3c0,0.2-0.1,0.3-0.1,0.4c-0.1,0.6-0.2,0.9-0.2,1.1c0,0.1,0,0.2,0.1,0.3c0.5,1.1,1,1.6,1,1.7
		c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.2-0.1,0.2-0.3c0,0,0,0,0-0.1c0,0-0.1-0.4-0.1-1c0-0.6,0.1-1.2,0.5-1.7c0,0,0.2,0,0.2,0l0.6-0.3
		c0.5-0.6,1.1-0.9,1.5-1c-0.3,0.3-0.5,0.8-0.7,2c0,0.2,0,0.3,0,0.5c0,0.8,0.2,1.8,0.3,2.2c-0.9,0.4-3.5,1.7-4.3,2.7
		c-0.7-0.8-1.2-1.4-1.2-2.2C86.9,69.7,87,69.3,87.2,68.8L87.2,68.8z"/>
				<path fill="#FFFFFF" d="M89.2,66.3c-0.1,0.4-0.2,0.9-0.2,1c0,0,0,0,0,0c0.2,0.4,0.4,0.8,0.6,1c0-0.1,0-0.2,0-0.3c0-0.6,0.1-1.4,0.6-2
		l0.5-0.2l0.3-0.1c0.9-1.1,2.2-1.3,2.2-1.3c0,0,0.1,0,0.1,0c0,0,0.2,0.2,0.3,0.2c0,0,0.1,0,0.1,0c0.5-0.2,1.1-0.5,2.6-0.7l0.1,0
		c0.5-0.1,1.5-0.3,2.2,0.9c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c1.7,1.7,3.7,2.5,5.3,3
		c4.6,1.5,10.2-1.6,12-2.7c0,0,0.3-0.1,0.3-0.2c1.1-0.6,2-1,3-0.9c0.2,0,0.4,0,0.6,0c0.5,0.1,0.8,0.4,1,0.7c0.8,1.5,0,2.3-0.4,2.6
		c-0.9,0.6-2.3,0.6-2.9-0.1c-0.3-0.3-0.4-0.6-0.5-0.9c0,0.1,0,0.1,0,0.2c0,0.3,0.1,0.7,0.1,0.9c0.6,1.2,2.1,2.3,4.6,1.7
		c1.3-0.3,2.1-1,2.5-2c0-0.1,0-0.2,0.1-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0
		c0-0.2,0-0.4,0-0.6c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0,0
		c-0.2-0.8-0.6-1.6-1-2.1c-1.2-1.4-4.1-1.7-6.6,0c-2.7,1.9-5,2.9-7.2,3c-5.1,0.2-7.5-1.4-9.4-2.6c-0.9-0.6-1.6-1.1-2.5-1.3
		c-2.7-0.8-4.6,0-6.1,0.7L91.3,63c-0.5,0.2-1.1,1-1.6,1.7c0,0-0.2,0.2-0.3,0.4c0,0.1,0.1,0.1,0.1,0.2C89.4,65.4,89.3,65.7,89.2,66.3
		L89.2,66.3z"/>
				<path fill="#FFFFFF" d="M131.9,42.3c-49.3,0-89.3,40.1-89.3,89.3c0,49.3,40.1,89.3,89.3,89.3c49.3,0,89.3-40.1,89.3-89.3
		C221.2,82.4,181.1,42.3,131.9,42.3L131.9,42.3z M131.9,219.2c-48.3,0-87.6-39.3-87.6-87.6c0-48.3,39.3-87.6,87.6-87.6
		c48.3,0,87.6,39.3,87.6,87.6C219.4,179.9,180.2,219.2,131.9,219.2L131.9,219.2z"/>
				<path fill="#FFFFFF" d="M131.3,8C63.8,8,8.8,62.9,8.8,130.4c0,67.5,54.9,122.5,122.4,122.5c67.5,0,122.5-54.9,122.5-122.5
		C253.7,62.9,198.8,8,131.3,8L131.3,8z M131.3,249.4c-65.6,0-118.9-53.4-118.9-118.9c0-65.6,53.4-118.9,118.9-118.9
		c65.6,0,118.9,53.4,118.9,118.9C250.2,196,196.9,249.4,131.3,249.4L131.3,249.4z"/>
				<path fill="#FFFFFF" d="M217.5,91.1c1.1,2.3,1.7,3,4.1,5.2l0,0l2.1,0.2l-0.5-0.4c-1.7-1.3-3-2.9-3.8-4.4c-1-2.1-1.2-4.3-0.5-6.3
		c0.7-2.1,2.4-3.8,4.7-5c1.3-0.6,2.5-0.9,3.5-0.9c2.4,0,4.6,1.6,5.9,4.3c0.8,1.7,1.3,3.6,1.5,5.7c0,0,0,0,0,0c-0.1,0.1-1.2,1-1.2,1
		l0.4,0.8l2.7-0.9l-0.1-0.3c-0.3-2.3-0.9-4.4-1.7-6.1c-1.3-2.8-4.6-5.4-7.4-5.8c-1.6-0.3-4,0.2-5.8,1
		C217.1,81.5,215.3,86.6,217.5,91.1L217.5,91.1z"/>
				<path fill="#FFFFFF" d="M222.7,97.8l-0.1-0.3l-1,0.3l0.1,0.3c0,0,0.4,1.3,0.4,1.3c0,0,0.5,2.1,0.5,2.1c0,0,0.1,0.2,0.1,0.2l0.1,0.2
		l0.9-0.2l-0.1-0.3c0-0.2,0-0.3,0-0.4c0-0.2,0-0.3,0.1-0.3h0.2h0.1c0,0,3.3,0.5,3.3,0.5l4,0.7c0,0,0,0,0.1,0
		c0.1,0.2,1.5,5.5,1.5,5.7c-0.2,0.2-5.5,4.5-5.5,4.5c-0.1,0-0.4,0.2-0.4,0.2c-0.1,0-0.2,0-0.6-0.8l-0.1-0.2l-0.2,0l-0.7,0.2l0,0.3
		c0,0.1,0.6,1.9,0.6,1.9c0,0,0.2,0.8,0.2,0.8l0.2,1.1c0,0,0.2,0.5,0.2,0.5l0.1,0.2l0.2,0.1l0.7-0.2l0-0.2c0-0.2-0.1-0.4-0.1-0.5
		c0-0.6,0.3-1,1.1-1.7c0,0,10-8.2,10-8.2l3.6-2.8l-0.4-0.1L224.3,99C223.2,98.8,223,98.6,222.7,97.8L222.7,97.8z M238.2,103.2
		c-0.5,0.4-3.8,3-4.1,3.3c-0.1-0.5-1-3.9-1.2-4.4C233.4,102.3,237.6,103.1,238.2,103.2L238.2,103.2z"/>
				<path fill="#FFFFFF" d="M229.7,117.9h-0.2c0,0-1.5,0.2-1.5,0.2c-0.5,0-0.7,0-1.4-0.4l-0.3-0.2l0.1,0.6c0,0,0.5,3,0.5,3
		c0,0,0.1,1.4,0.1,1.4c0,0,0,0.4,0,0.4l0,0.4c0,0,0.2,2.4,0.2,2.4l0,0.2c0.1,0.9,0.1,1.1,0.4,1.6l0.1,0.1l2-0.1l0.7-0.1l0.1-0.1
		l0.5-0.9l-0.5,0.1c-0.5,0-1.1,0-1.3-0.1c0,0-0.1-0.1-0.1-0.1c0-0.1-0.4-2.6-0.4-2.6l-0.4-2.6l0-0.2c0,0,0-0.7,0.1-0.9
		c0.1,0,1.3-0.2,1.3-0.2c0,0,1.2-0.1,1.2-0.1c0,0,0.2,0,0.2,0c0,0,9-1,9-1l0.2,0c0,0,1.8-0.1,1.8-0.1c0.9-0.1,1.3,0.2,1.4,1l0,0.2
		l0.9-0.1l-0.1-0.4c0,0-0.3-1.9-0.3-1.9c0,0,0-2,0-2l0-0.4l-1,0.1l0,0.3c0,0.5,0,0.7-0.1,0.9c-0.2,0.2-0.5,0.3-1.1,0.4L229.7,117.9
		L229.7,117.9z"/>
				<path fill="#FFFFFF" d="M244.6,130.5l-0.9,0v0.4c-0.1,0.8-0.2,0.9-1,0.9l-6.7-0.1l-6.5,0c-1.1,0-1.2-0.1-1.3-1.1l0-0.2l-0.2-0.1
		l-0.7,0l0,4.6l1,0l0-0.3c0.1-0.8,0.1-0.8,0.4-0.9c0.4-0.1,1.6-0.1,6.2-0.1l1,0c6.1,0.1,7,0.1,7.5,0.3c0.4,0.2,0.5,0.7,0.5,1v0.2
		l1,0l-0.1-0.4c0,0-0.1-1.7-0.1-1.7l0.1-2.2L244.6,130.5L244.6,130.5z"/>
				<path fill="#FFFFFF" d="M27.2,91.3c0.4-0.8,0.6-0.9,1.6-1c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.1,0.1c1.2,0.5,3.4,1.4,6.5,2.9l1.5,0.7
		c2.9,1.4,3.9,2,4.4,2.4c0.1,0.1,0.2,0.2,0.2,0.4c0,0.2,0,0.4-0.2,0.7c0,0.1-0.1,0.2-0.1,0.2l-0.1,0.2l0.2,0l0.7,0.3l0.2-0.4
		c0,0,0.6-1.6,0.6-1.6c0,0,1-1.7,1-1.7c0,0,0.2-0.4,0.2-0.4l-0.9-0.4l-0.1,0.2c-0.4,0.8-0.6,0.9-1.3,0.6l-2.1-0.8
		c0,0-2.4-1.1-2.4-1.1l-0.2-0.1c0,0-5.4-2.6-6.1-2.9c1-0.3,3.5-1.1,7.5-2.3c2.1-0.6,5.4-1.7,11.2-3.6l0.7-0.3l-0.7-0.3
		c0,0-6.2-2.7-6.2-2.7L35.2,76c-0.4-0.2-0.5-0.3-0.5-0.5c0-0.2,0.1-0.4,0.2-0.7l0.1-0.2l-0.1-0.2l-0.7-0.3l-0.1,0.4
		c-0.4,1.1-0.5,1.4-0.5,1.5c0,0-1.1,1.8-1.1,1.8c0,0-0.2,0.4-0.2,0.4l0.9,0.3l0.2-0.3c0.4-0.9,0.6-1,2-0.3l1.9,0.8
		c0,0,6.5,3.2,8.2,4c-0.8,0.3-10.3,3.4-10.3,3.4c0,0-7.1,1.8-7.1,1.8c0,0-0.4,0.1-0.4,0.1l0,0.1l0,0.2c0-0.1-0.3,0.7-0.3,0.7
		L27,89.6c0,0-0.7,1.2-0.7,1.2l-0.2,0.4l0.9,0.4L27.2,91.3L27.2,91.3z"/>
				<path fill="#FFFFFF" d="M51.3,194c0,0,0.1-0.1,0.1-0.1l0.2-0.1c0,0,1.7-0.9,1.7-0.9c1.1-0.6,1.9-1,2.3-1c0.2,0,0.3,0,0.7,0.6
		c0,0,0.2,0.2,0.2,0.2l0.1,0.1l0.1-0.1l0.7-0.4l-0.3-0.4c0,0-1-1.4-1-1.4c0,0-1-1.8-1-1.8c0,0-0.2-0.3-0.2-0.3l-0.8,0.5l0.1,0.1
		l0.1,0.2c0.2,0.5,0.3,0.7,0.3,0.9c0,0.2-0.1,0.3-0.3,0.4l-0.2,0.2c-0.5,0.2-2.2,1.2-5,2.8c-1.9,1.1-3.7,2-5.3,2.8
		c1-2.1,2.2-4.3,3.5-6.5c2.2-3.9,3.8-6.6,4.3-7.8l0.3-0.6c0,0-16.3,1.3-17.5,1.4c0.9-0.7,1.5-1.1,4.3-2.8c0,0,6.2-4.1,6.2-4.1
		c0.6-0.4,0.7-0.4,1.4,0.4l0.1,0.1l0.2,0l0.6-0.4l-0.2-0.3c0,0-1.1-1.7-1.1-1.7c0,0-1-1.8-1-1.8c0,0-0.1-0.2-0.1-0.2l-0.1-0.1
		l-0.8,0.5l0.2,0.3c0.4,0.9,0.3,1-0.2,1.4l-6.1,4.1l-4.1,2.8c0,0-0.4,0.3-0.4,0.3c-1.6,1-1.7,0.9-2.5,0.1l-0.1-0.1l-0.7,0.5l1.7,2.8
		l0.4,0c0,0,4.8-0.4,4.8-0.4l4-0.4c1.5-0.2,4.1-0.3,7.8-0.5c-0.3,0.6-1.5,2.7-3.4,6.2l-0.5,0.9l-4.2,7.6c0,0-0.2,0.3-0.2,0.3
		l0.1,0.2l0.6,0.9c0,0,0.4,0.5,0.4,0.5l0.3,0.6l0.3,0.5l0.8-0.5l-0.1-0.2c-0.2-0.3-0.3-0.6-0.3-0.8c0-0.2,0.1-0.5,0.4-0.8
		C42.8,198.5,51.3,194,51.3,194L51.3,194z"/>
				<path fill="#FFFFFF" d="M29.4,173.8l6.2-2.5l6-2.4c1-0.4,1.2-0.4,1.6,0.5l0.1,0.1l0.2,0l0.7-0.3l-1.7-4.2l-0.9,0.4l0.1,0.3
		c0.2,0.8,0.2,0.8,0,1c-0.3,0.3-1.4,0.7-5.7,2.4l-0.9,0.4c-5.7,2.2-6.5,2.6-7,2.6c-0.4,0-0.7-0.5-0.9-0.7l-0.1-0.2l-0.9,0.4l0.2,0.3
		c0,0,0.7,1.5,0.7,1.5l0.8,2.1l0.1,0.3l0.9-0.4l-0.1-0.4C28.6,174.3,28.7,174.1,29.4,173.8L29.4,173.8z"/>
				<path fill="#FFFFFF" d="M66,199.7c-4.4-4.3-10.6-4.3-14.9,0c-1.7,1.7-2.5,3.6-2.5,5.5c0,2.2,1,4.4,3.1,6.4c1.3,1.3,3,2.3,4.7,3
		c3.5,1.3,6.9,0.4,9.7-2.4C69.9,208.4,69.8,203.5,66,199.7L66,199.7z M64.1,211.2c-1,1.1-2.2,1.8-3.4,2.3c-2.4,0.9-5.3,0.1-7.4-2
		c-1-1-1.8-2.2-2.2-3.5c-0.9-2.7-0.2-5.2,2-7.5c3.5-3.6,8-3.9,11.2-0.8c1.1,1,1.8,2.3,2.2,3.8C67.3,206.4,66.5,208.8,64.1,211.2
		L64.1,211.2z"/>
				<path fill="#FFFFFF" d="M22.5,132.5c1.3,1,3.6,1.8,5.6,1.9c2.5,0.1,4.7-0.6,6.4-2.2c1.7-1.5,2.7-3.7,2.8-6.2c0.1-2.5,0-3.5-1.1-6.6
		l0-0.1l-1.8-1.2l0.3,0.6c0.8,1.9,1.3,4,1.2,5.7c-0.2,4.9-4,8.1-9.1,7.8c-1.4-0.1-2.6-0.4-3.5-0.9c-1.9-1.1-3.1-3.4-3.1-6.1
		c0-0.2,0-0.3,0-0.5c0.1-1.9,0.6-3.8,1.4-5.7c0,0,0,0,0,0c0.2,0,1.5-0.3,1.5-0.3l0-0.9l-2.8-0.5l-0.1,0.3c-0.9,2.2-1.4,4.3-1.5,6.2
		C18.6,126.9,20.2,130.7,22.5,132.5L22.5,132.5z"/>
				<path fill="#FFFFFF" d="M34.4,161.9c3.1-0.7,4.3-1,4.9-0.9c0.3,0,0.5,0.1,0.8,0.8c0,0.1,0.1,0.2,0.1,0.2l0.1,0.2l0.1-0.1L41,162
		l-0.1-0.4c0,0-0.5-1.7-0.5-1.7l-0.3-2c0,0-0.1-0.5-0.1-0.5l-1,0.2l0.1,0.3c0.1,0.3,0.1,0.5,0.1,0.6c0,0.4-0.2,0.5-0.7,0.7l-2.1,0.6
		l-2.6,0.7c0,0-5.3,1.2-6.8,1.6c0.6-0.9,2-3,4.4-6.5c1.2-1.8,3.2-4.7,6.5-9.9l0.4-0.6l-0.7,0.2c0,0-6.5,1.8-6.5,1.8l-9.2,2
		c-0.7,0.1-0.7,0.1-1.1-0.8l0-0.2l-0.2-0.1l-0.7,0.2l0.1,0.4c0.4,1.1,0.5,1.4,0.5,1.5c0,0,0.3,2.1,0.3,2.1c0,0,0.1,0.5,0.1,0.5
		l0.9-0.3l-0.1-0.3c-0.2-1-0.2-1.1,1.4-1.5l2-0.5c0,0,0.2-0.1,0.2-0.1s6.8-1.6,8.7-2c-0.5,0.7-5.9,9.2-5.9,9.2c0,0-4.4,5.9-4.4,5.9
		c0,0-0.2,0.3-0.2,0.3l0.1,0.1l0.1,0.1c0-0.1,0.2,0.7,0.2,0.7l0.2,0.6c0,0,0.2,1.4,0.2,1.4l0.1,0.4l1-0.2l-0.1-0.3
		c-0.1-0.3-0.1-0.5-0.1-0.6c0-0.4,0.2-0.7,0.7-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.2,0,0.2,0c1.3-0.4,3.5-1,6.8-1.8L34.4,161.9
		L34.4,161.9z"/>
				<path fill="#FFFFFF" d="M20.6,142.5c0-0.8,0.1-0.9,0.9-1l6.7-0.5l6.5-0.5c1.1-0.1,1.2,0,1.4,1l0,0.2l0.2,0.1l0.7-0.1l-0.4-4.6l-1,0.1
		l0,0.3c0,0.9,0,0.9-0.3,1c-0.4,0.1-1.5,0.3-6.2,0.6l-1,0.1c-6.1,0.5-7,0.5-7.5,0.4c-0.4-0.1-0.6-0.7-0.6-1l0-0.2l-1,0.1l0.1,0.4
		c0,0,0.2,1.7,0.2,1.7c0,0,0.1,2.2,0.1,2.2l0,0.3l1-0.1L20.6,142.5L20.6,142.5z"/>
				<path fill="#FFFFFF" d="M82.7,213.3l-1.3-0.9c-5.2-3.3-10.8-2.2-14,2.8c-1.3,2-1.7,3.9-1.3,5.7c0.4,2,1.8,3.7,4.1,5.2l3.6,2.1l2.1,1.2
		c0,0,1.9,1.3,1.9,1.3l0.3,0.1l0.5-0.8l-0.3-0.2c-0.4-0.3-0.6-0.5-0.6-0.7c0-0.2,0.1-0.5,0.4-0.9l4.4-7l0.7-1.2c0,0,1.7-2.6,1.7-2.6
		c0.4-0.6,0.7-0.9,1.6-1.2c0,0,0.1,0,0.1,0l0.5-0.1l-1.4-0.9C85.6,215.2,82.7,213.3,82.7,213.3L82.7,213.3z M83.6,215.8
		c-0.4,0.7-1.7,2.8-3.3,5.3c-2.7,4.3-4,6.1-4.7,6.7c-1.2-0.5-1.6-0.8-2.9-1.6c-1.7-1.1-2.7-2-3.6-3.2c-0.6-0.9-1-2-1-3.1
		c0-1.4,0.5-3,1.5-4.5c0.7-1.1,1.5-2,2.6-2.7c2.3-1.6,4.9-1.4,7.9,0.6C80.8,213.6,82.1,214.6,83.6,215.8
		C83.6,215.8,83.6,215.8,83.6,215.8L83.6,215.8z"/>
				<path fill="#FFFFFF" d="M192.9,208.2c-0.1-1,0-1.3,0.6-1.9l0.2-0.2l-0.6-0.8l-0.3,0.2c0,0-1.1,0.8-1.1,0.8c0,0-1.8,1.1-1.8,1.1
		c0,0-0.2,0.1-0.2,0.1l-0.1,0.1l0.5,0.8l0.3-0.2c0.4-0.2,0.6-0.3,0.7-0.2c0,0,0.1,0.2,0.1,0.2l0,0.1c0,0,0.6,3.2,0.6,3.2l0.6,4
		c0,0,0,0,0,0.1c-0.2,0.1-4.7,3.2-4.9,3.3c-0.2-0.1-6-3.8-6-3.8c-0.1-0.1-0.4-0.3-0.4-0.3c0,0,0-0.1,0-0.1c0-0.1,0.1-0.3,0.6-0.8
		l0.1-0.1l-0.1-0.2l-0.4-0.6l-0.3,0.1c0,0-1.6,1.2-1.6,1.2c0,0-0.7,0.4-0.7,0.4l-0.9,0.5c0,0-0.4,0.3-0.4,0.3l-0.2,0.1l0,0.2
		l0.4,0.6l0.2-0.1c0.8-0.4,1.2-0.5,2.4,0.3c0,0,11.1,6.7,11.1,6.7c0,0,0.3,0.2,0.3,0.2c0,0,3.6,2.3,3.6,2.3l0-0.4L192.9,208.2
		L192.9,208.2z M193.5,222.8c-0.6-0.4-4.1-2.6-4.5-2.8c0.4-0.3,3.3-2.2,3.7-2.5C192.9,218,193.4,222.1,193.5,222.8L193.5,222.8z"/>
				<path fill="#FFFFFF" d="M243.5,140.7l-0.1,0.2c-0.1,0.6-0.3,0.7-0.5,0.8c-0.4,0.1-2.4,0-7-0.6l-6.9-0.8c-1-0.1-1.2-0.3-1.1-1.1l0-0.2
		l-0.2-0.1l-0.7-0.1l0,0.4c0,0-0.1,1.9-0.1,1.9c0,0-0.4,1.9-0.4,1.9c0,0,0,0.4,0,0.4l1,0.1l0-0.3c0.1-0.6,0.2-0.7,0.2-0.8
		c0.1-0.2,0.6-0.2,1.8-0.1c0,0,0.2,0,0.2,0c0,0,4.4,0.4,5.3,0.5c0,0.3-0.1,1.4-0.1,1.4c0,0-0.1,1-0.1,1c0,0-0.4,3.2-0.4,3.2
		c0,0,0,0.1,0,0.1l0,0.2l0.3,0.1l0.5,0.2c0,0,0.9,0.4,0.9,0.4l0.1-0.2l0-0.1c0,0,0-2.4,0-2.4l0.2-2.3c0,0,0.2-1,0.2-1.3
		c0,0,3.1,0.4,3.1,0.4c0.9,0.1,2.8,0.3,3.1,0.5c0,0,0,0,0,0c0,0-0.5,4.4-0.5,4.4c-0.1,1.1-0.3,1.1-0.7,1.1l-0.8-0.3l0.1,0.4l0.3,0.8
		l0.9,0.2c0.1,0,1,0.3,1,0.3l0.2,0.1l0.1-0.2l0.2-0.9l0.2-2.3l0.2-2.2c0,0,0.1-2.2,0.1-2.2c0,0,0.3-2.1,0.3-2.1l0-0.2l-0.2-0.1
		L243.5,140.7L243.5,140.7z"/>
				<path fill="#FFFFFF" d="M234.1,177.5c-0.4,0.6-0.4,0.6-0.8,0.6c-0.6-0.1-1.1-0.3-3.9-1.8c-0.1,0-1.3-0.7-1.3-0.7c0,0-7.4-4-7.4-4
		c-0.7-0.4-0.7-0.4-0.4-1.3c0,0,0.1-0.2,0.1-0.2l0-0.1l-0.1,0l-0.6-0.4l-0.3,0.6l-0.2,0.4c0,0-0.5,1.2-0.5,1.2c0,0-0.4,0.6-0.4,0.6
		l-0.1,0.2c0,0-0.4,0.6-0.4,0.6c0,0-0.2,0.5-0.2,0.5l0.2,0.1l0.6,0.4l0.1-0.2l0.6-0.7c0-0.1,0.4-0.1,1,0.2l5,2.6l3,1.5
		c2.4,1.3,3.7,2,4.1,2.4c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.4,1.1-0.4,1.1c-1.1,2-2.3,2.6-3.6,1.9
		c-1.1-0.6-1.8-1.9-1.7-3.2c0.1-0.8,0.1-1,0.6-2l0.1-0.3l-0.5,0.1c0,0-5.9,1.3-5.9,1.3c0,0-5,1.2-5,1.2c-1.6,0.4-2.3,0.8-2.7,1.5
		l-0.3,0.9c-0.1,0.9-0.1,1.1,0.2,1.5l0.2,0.3l0.2-0.5c0.4-0.7,0.7-1,1.2-1.2c3.5-1.2,8.7-2.6,10.8-2.8c0,0,0,0.1,0,0.1
		c0,0,0.2,1.9,0.2,1.9c0.1,1.4,1,2.8,2.1,3.4c1.7,0.9,3.5,0,4.8-2.4l1.5-3.1l1.1-1.8l0.2-0.3l-0.9-0.4L234.1,177.5L234.1,177.5z"/>
				<path fill="#FFFFFF" d="M233.9,151.6c-5.1-1.5-9.4,0.9-10.9,6c-1.7,5.9,1.2,11.4,7,13.1c2.2,0.7,4.4,0.5,6.1-0.4c1.9-1,3.4-3,4.2-5.7
		c0.5-1.8,0.7-3.7,0.4-5.5C240.1,155.3,237.7,152.8,233.9,151.6L233.9,151.6z M239.4,163.2c-0.4,1.4-1.1,2.6-2,3.6
		c-1.9,2.1-4.4,2.6-7.5,1.7c-4.8-1.4-7.2-5.2-6-9.5c0.4-1.4,1.2-2.7,2.3-3.7c2.2-2,4.6-2.5,7.9-1.6c1.4,0.4,2.7,1.1,3.6,1.9
		C239.6,157.4,240.2,160.3,239.4,163.2L239.4,163.2z"/>
				<path fill="#FFFFFF" d="M223.9,195.8c-0.6,0.7-0.8,0.7-1.8,0.6c0,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.1-0.1c-1.1-0.8-2.9-2.2-5.6-4.3
		l-1.3-1.1c-2.4-2-3.3-2.8-3.7-3.3c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.2,0.1-0.4,0.3-0.7c0-0.1,0.1-0.2,0.1-0.2l0.1-0.1l-0.1-0.1
		l-0.6-0.5l-0.2,0.3c0,0-1,1.4-1,1.4c0,0-1.4,1.4-1.4,1.4c0,0-0.3,0.4-0.3,0.4l0.8,0.6l0.2-0.2c0.6-0.7,0.8-0.8,1.4-0.3l1.8,1.3
		c0,0,2.1,1.7,2.1,1.7c0,0,0.2,0.1,0.2,0.1s4.6,3.8,5.2,4.3c-1,0.1-3.6,0.2-7.9,0.4c-2.1,0.1-5.7,0.3-11.8,0.8c0,0-0.8,0.1-0.8,0.1
		l0.6,0.5c0,0,5.3,4.1,5.3,4.1l7.2,6.1c0.3,0.2,0.4,0.4,0.4,0.5c0,0.2-0.1,0.4-0.4,0.8l-0.1,0.2l0.1,0.2l0.6,0.5l0.2-0.3
		c0.6-1,0.8-1.2,0.9-1.3c0,0,1.5-1.5,1.5-1.5c0,0,0.3-0.4,0.3-0.4l-0.8-0.5l-0.2,0.3c-0.6,0.8-0.8,0.8-2-0.2l-1.6-1.2
		c0,0-5.5-4.6-7-5.9c0.8-0.1,10.9-0.9,10.9-0.9c0.1,0,7.3-0.1,7.3-0.1c0,0,0.4,0,0.4,0l0.1-0.3c0,0.1,0.4-0.6,0.4-0.6l0.4-0.5
		c0,0,1-1,1-1l0.3-0.3l-0.8-0.6L223.9,195.8L223.9,195.8z"/>
				<path fill="#FFFFFF" d="M207.9,213.6c-0.7,0.5-0.8,0.5-1.4-0.1l-4.5-5l-4.4-4.8c-0.7-0.8-0.7-1-0.1-1.7l0.1-0.1l-0.1-0.2l-0.5-0.5
		l-3.4,3l0.6,0.7l0.3-0.2c0.7-0.5,0.7-0.5,0.9-0.3c0.4,0.2,1.2,1.1,4.2,4.5l0.6,0.7c4,4.6,4.6,5.2,4.8,5.7c0.2,0.4-0.2,0.8-0.4,1.1
		l-0.1,0.1l0.6,0.7l0.2-0.3c0,0,1.2-1.2,1.2-1.2l1.7-1.4l0.2-0.2l-0.6-0.7L207.9,213.6L207.9,213.6z"/>
				<path fill="#FFFFFF" d="M120.5,20.1c0.7,0,0.8,0.1,0.9,0.4c0.2,0.5,0.3,1.1,0.4,4.3c0,0,0,1.4,0,1.4c0,0,0.2,8.4,0.2,8.4
		c0,0.8,0,0.8-0.9,1c0,0-0.2,0.1-0.2,0.1l-0.1,0l0,0.1l0,0.8l0.7,0l0.5,0c0,0,1.4-0.1,1.4-0.1l0.7,0l0.2,0c0,0,0.7,0,0.7,0
		c0,0,0.6-0.1,0.6-0.1l0-0.2l0-0.7l-0.2,0l-0.9-0.1c-0.1,0-0.3-0.3-0.3-1c0,0-0.3-5.6-0.3-5.6c0,0-0.1-3.4-0.1-3.4
		c0-1.3-0.1-2.3-0.1-3.1c0-0.8,0-1.3,0.1-1.6c0,0,0,0,0-0.1c0,0,0,0,0.1,0c0,0,0,0,0,0l1.2-0.2c2.3-0.1,3.4,0.6,3.5,2.2
		c0,1.2-0.7,2.5-1.9,3c-0.7,0.3-1,0.4-2,0.5l-0.4,0l0.3,0.4c0,0,4.1,4.5,4.1,4.5l3.6,3.7c1.1,1.2,1.8,1.6,2.7,1.6l1-0.2
		c0.9-0.4,1-0.4,1.2-0.9l0.2-0.3l-0.6,0c-0.7,0-1.1-0.1-1.6-0.5c-2.8-2.5-6.6-6.3-7.8-7.9c0,0,0.1-0.1,0.1-0.1c0,0,1.5-1.1,1.5-1.1
		c1.1-0.8,1.9-2.3,1.9-3.5c-0.1-1.9-1.8-3-4.5-3l-3.4,0.3l-2.1,0l-0.4,0l0.1,1L120.5,20.1L120.5,20.1z"/>
				<path fill="#FFFFFF" d="M155.7,38.3l-0.3-0.1c-0.8-0.3-0.8-0.3-0.8-0.6c0-0.4,0.2-1.6,1.2-6.1l0.2-0.9c1.4-5.9,1.6-6.8,1.9-7.3
		c0.2-0.4,0.8-0.3,1.1-0.3l0.2,0l0.2-1l-0.4,0l-1.7-0.3c0,0-2.1-0.5-2.1-0.5l-0.3-0.1l-0.2,0.9l0.4,0.1c0.8,0.2,0.9,0.4,0.7,1.1
		l-1.5,6.5c0,0-1.4,6.4-1.4,6.4c-0.2,1.1-0.4,1.2-1.3,1.1l-0.2,0l-0.1,0.2l-0.2,0.7l4.5,1L155.7,38.3L155.7,38.3z"/>
				<path fill="#FFFFFF" d="M141.4,37.2c3.6,0.4,7.2-2.2,7.5-5.5c0.3-2.3-1-3.8-3.8-4.4l-0.7-0.2c-2.5-0.6-3.5-1.7-3.3-3.6
		c0.2-1.9,1.7-2.9,3.8-2.7c1.8,0.2,3.1,1,3.1,1.6c0,0,0.1,1.3,0.1,1.3l0.9,0.2l0.6-2.9l-0.1-0.1c-1.2-1-1.8-1.3-3.4-1.4
		c-1.7-0.2-3.4,0.3-4.7,1.3c-1.2,0.9-1.9,2.2-2.1,3.6c-0.1,0.7,0,1.5,0.4,2.2c0.6,1.3,1.4,1.8,3.6,2.3L144,29
		c1.9,0.5,2.2,0.6,2.7,1.5c0.3,0.5,0.4,1.5,0.3,2.2c-0.3,2.2-2,3.4-4.6,3.1c-1.4-0.2-2.3-0.5-2.7-1c-0.3-0.4-0.4-0.9-0.3-1.7l0-0.4
		l-0.9-0.1l-0.1,0.4c0,0-0.3,1.3-0.3,1.3c0,0-0.4,1.1-0.4,1.1c0-0.1,0,0.1,0,0.1l-0.1,0.2l0.1,0.1C138.6,36.5,140.1,37.1,141.4,37.2
		L141.4,37.2z"/>
				<path fill="#FFFFFF" d="M161,40.8l0.2,0.1c0.1,0,2.1,0.6,2.1,0.6c0,0,1.8,0.7,1.8,0.7l0.2,0.1l0.2-0.1l0.3-0.8l-0.3-0.2
		c-0.7-0.4-0.9-0.5-0.9-0.8c0-0.2,0.1-0.4,0.2-0.7l0.8-2.3l1.2-3.6c0,0,0.7-2.1,0.7-2.1c0.8-2.2,1.4-3.8,1.8-4.8c0,0,0,0,0,0
		c0.2,0.1,2.6,0.9,2.6,0.9c1.2,0.4,1.4,0.5,1.9,0.9c0,0,0,0,0,0.1c0,0.1,0,0.3,0,0.3c0,0,0,0.4,0,0.4c0,0.1,0,0.2,0,0.2l0,0.2
		l0.7,0.3l1.2-2.5l-0.4-0.1c0,0-7.8-2.5-7.8-2.5c0,0-4.9-1.8-4.9-1.8l-0.4-0.1l-1,2.6l0.6,0.8l0.2-0.4c0.6-1,0.8-1,1.4-0.8
		c0,0,3.9,1.2,3.9,1.2c0,0,0,0,0.1,0c0,0.1,0,0.1,0,0.1c-0.6,2.3-1.4,4.6-2.2,6.7c0,0-2.3,6.2-2.3,6.2c0,0.1-0.2,0.5-0.2,0.5
		c0,0-0.3,0.2-0.3,0.2l-1-0.1c0,0-0.2,0-0.2,0l-0.2,0l0,0.2l-0.2,0.8L161,40.8L161,40.8z"/>
				<path fill="#FFFFFF" d="M35.2,115.8c0.6,0.5,0.8,0.8,0.8,1.4c0,0.1,0,0.3,0,0.4l0,0.3l1,0.2l0.1-0.3c0,0,0.3-1.4,0.3-1.4
		c0,0,0.6-2.1,0.6-2.1c0,0,0-0.2,0-0.2l0-0.2l-0.9-0.2l-0.1,0.3c-0.1,0.4-0.3,0.6-0.4,0.6c0,0-0.2-0.1-0.2-0.1l-0.1-0.1
		c0,0-2.6-2-2.6-2l-3.2-2.5c0,0,0,0-0.1,0c0.1-0.2,1.4-5.5,1.4-5.7c0.2,0,7-1.3,7-1.3c0.1,0,0.5,0,0.5,0c0.1,0,0.2,0.1,0.1,1l0,0.2
		l0.2,0.1l0.7,0.1l0.1-0.3c0,0,0.4-2,0.4-2c0,0,0.2-0.8,0.2-0.8l0.3-1c0,0,0.1-0.5,0.1-0.5l0-0.2l-0.1-0.2l-0.7-0.2l-0.1,0.2
		c-0.3,0.9-0.6,1.2-2,1.4l-12.7,2.4l-4.5,0.7l0.3,0.3L35.2,115.8L35.2,115.8z M24.9,105.4c0.7-0.1,4.7-0.8,5.2-0.9
		c-0.1,0.5-1,3.9-1.1,4.4C28.7,108.5,25.5,105.9,24.9,105.4L24.9,105.4z"/>
				<path fill="#FFFFFF" d="M205.2,69.2l-0.1-0.1l-0.2,0.1l-0.5,0.5l0.2,0.3c0,0,1.3,1.4,1.3,1.4c0,0,1.1,1.6,1.1,1.6c0,0,0.3,0.3,0.3,0.3
		l0.8-0.6l-0.2-0.3c-0.3-0.5-0.4-0.7-0.4-0.7c0,0,0,0,0-0.1c0-0.2,0.3-0.6,1.2-1.3c0,0,0.1-0.1,0.1-0.1c0,0,3.4-2.8,4.1-3.4
		c0.2,0.2,0.9,1.1,0.9,1.1l0.6,0.8c0,0,2,2.5,2,2.5c0,0,0.1,0.1,0.1,0.1l0.1,0.1l0.2-0.1l0.5-0.2c0,0,0.9-0.3,0.9-0.3l-0.1-0.3
		l-0.1-0.1c0,0-1.7-1.7-1.7-1.7l-1.5-1.8c0,0-0.6-0.9-0.7-1.1c0,0,2.4-1.9,2.4-1.9c0.7-0.6,2.2-1.7,2.5-1.8c0,0,0,0,0,0
		c0,0,2.8,3.5,2.8,3.5c0.3,0.4,0.5,0.7,0.5,0.9c0,0,0,0,0,0l-0.2,0.3c0,0-0.8,0.4-0.8,0.4l0.4,0.2l0.8,0.3l0.8-0.5
		c0.1,0,0.9-0.5,0.9-0.5l0.2-0.1l-0.1-0.2l-0.4-0.8l-1.5-1.8l-1.4-1.7c0,0-1.5-1.6-1.5-1.6c0,0-1.4-1.9-1.4-1.9l-0.2,0l-0.5,0.4
		l0.1,0.2c0.4,0.5,0.3,0.7,0.2,0.9c-0.2,0.3-1.7,1.7-5.4,4.5l-5.5,4.3C206.1,69.8,205.8,69.8,205.2,69.2L205.2,69.2z"/>
				<path fill="#FFFFFF" d="M176.1,29.2c0,0,0.3,0,0.3,0c0,0,0.4,0.2,0.4,0.2c0.5,0.3,0.9,0.7,1.1,1.2c0.8,2,1.2,5.1,1.2,9.6
		c0,0.1,0,0.2,0,0.3c0,0-0.1,0.8-0.1,0.8c0,0-0.7,1.3-0.7,1.3c-1.4,2.6-1.6,3-1.8,3.2c-0.1,0.2-0.4,0.3-1.2-0.1c0,0-0.2-0.1-0.2-0.1
		l-0.2-0.1l0,0.2l-0.2,0.6l0.2,0.2c0,0,1.7,0.8,1.7,0.8l1.9,1.1c0,0,0.2,0.1,0.2,0.1l0.2,0.1l0.4-0.8l-0.3-0.2
		c-0.4-0.2-0.6-0.4-0.7-0.6c0,0,0-0.1,0-0.1c0-0.3,0.3-1,1-2.3c1.2-2.2,1.5-2.6,2.2-3l7.3-3.9c0,0,2-0.9,2-0.9l1.6-0.4
		c0,0,0.6-0.2,0.6-0.2l-0.5-0.3c0,0-1-0.4-1-0.4c0,0-0.8-0.4-0.8-0.4l-0.1,0l-0.2,0.1c0,0-8.9,5-9.4,5.3c0-0.1-0.1-4.6-0.1-4.6
		c0-4.2-0.7-6.2-2.2-7c-1-0.5-1.6-0.5-2.8,0.1l-0.6,0.3L176.1,29.2L176.1,29.2z"/>
				<path fill="#FFFFFF" d="M195.1,61.1c4.5,4.1,10.8,3.9,14.9-0.6c3.4-3.7,3-8.2-1-11.9c-1.4-1.3-3-2.2-4.8-2.8c-3.6-1.1-6.9-0.2-9.6,2.8
		C190.9,52.6,191.2,57.5,195.1,61.1L195.1,61.1z M196.5,49.5c1-1.1,2.1-1.9,3.3-2.4c2.3-1,5.3-0.3,7.5,1.7c1.1,1,1.9,2.2,2.3,3.4
		c1,2.6,0.4,5.2-1.8,7.5c-1.8,1.9-3.9,3.1-6,3.1c-1.8,0.1-3.6-0.6-5.1-2c-1.1-1-1.9-2.3-2.3-3.7v0C193.6,54.4,194.2,52,196.5,49.5
		L196.5,49.5z"/>
				<path fill="#FFFFFF" d="M84.2,30c0.8-0.2,1-0.2,1.4,0.1c0,0,0.6,0.5,0.6,0.5c0,0,5.2,5.1,5.2,5.1c3.6,3.7,5.5,5.6,5.9,6.2l0.7,0.9
		l0.2,0.2c0,0,0.1,0.1,0.1,0.1l0.2,0.2l0.2-0.2l0.9-1.4l0-0.2c-0.1-1.9,0.1-4.8,0.5-8.6l0.1-0.9l0.8-7.4c0,0,0.1-0.6,0.1-0.6
		l0.2-0.3c0,0,0.6-0.3,0.6-0.3l0.2-0.1l0-0.2l-0.3-0.7l-0.3,0.1c0,0-1.7,0.7-1.7,0.7c0,0-1.6,0.6-1.6,0.6l-0.4,0.1l0.3,0.9l0.6-0.2
		c0.4-0.1,0.4,0,0.5,0.1l0.1,0.8l-0.5,6.9l-0.1,1.8c-0.2,3.1-0.4,4.9-0.5,5.4c-0.8-0.8-2.5-2.5-5-5.1c0,0-5.3-5.6-5.4-5.6
		c0.1-0.1,0.2-0.2,0.2-0.2c0,0,0.5-0.3,0.5-0.3c0,0,0.1-0.1,0.1-0.1l0.1-0.1l-0.3-0.8l-0.3,0.1L86,28.4c0,0-2.2,0.7-2.2,0.7
		l-0.3,0.1l0.3,0.9L84.2,30L84.2,30z"/>
				<path fill="#FFFFFF" d="M105.4,22.9c0.6-0.1,0.7,0,0.8,0.2c0,0,0.2,0.6,0.2,0.6c0,0,1.9,9,1.9,9l0.3,1.4l0.4,2c0.2,1.3,0.2,1.7,0,2.6
		l-0.1,0.3l0.5-0.1c0,0,3-0.7,3-0.7c0,0,3.8-0.6,3.8-0.6c0,0,2-0.4,2-0.4l0.6-0.3l0.1-0.4l0-0.1c0-0.1-0.1-0.8-0.1-0.8
		c0-0.1,0-0.9,0-0.9v-0.1l-0.1-0.1l-1-0.8l0,0.5c0.1,0.5,0,1.1-0.1,1.5c-0.1,0.2-1.3,0.5-5.5,1.3c0,0-0.5,0.1-0.7,0.1
		c-0.2-0.6-0.5-1.6-0.8-3.1l-0.1-0.4c0,0-0.5-2.8-0.6-3.3c0.4-0.1,5.4-1.1,5.4-1.1l0.4-1.6c0,0-5.7,1.1-6.1,1.2
		c-0.1-0.4-0.9-4.7-0.9-4.7c0,0-0.1-0.2-0.1-0.2c-0.1-0.3-0.1-0.6-0.1-0.8c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0l2.7-0.6
		c2.4-0.5,2.7-0.3,3.1,0.6l0.1,0.3l0.7-0.6l-0.1-0.5c-0.1-0.7-0.2-1.1-0.1-1.4l0-0.2l-0.3-0.1c-0.4-0.1-1,0-1.8,0.1l-0.3,0.1
		c0,0-2.3,0.5-2.3,0.5l-2.4,0.5c0,0-2.4,0.4-2.4,0.4l-0.2,0l-0.1,0.2l0.2,0.7L105.4,22.9L105.4,22.9z"/>
				<path fill="#FFFFFF" d="M77.9,33.7c0.8-0.3,0.9-0.3,1.3,0.4l3.1,5.9l3.1,5.7c0.5,1,0.5,1.1-0.3,1.7l-0.1,0.1l0,0.2l0.3,0.6l4-2.1
		l-0.5-0.9l-0.3,0.2c-0.8,0.3-0.8,0.3-1,0.1c-0.3-0.3-0.9-1.3-3-5.4l-0.5-0.9c-2.8-5.4-3.2-6.2-3.3-6.7c-0.1-0.4,0.4-0.8,0.6-0.9
		l0.2-0.1l-0.5-0.9L80.9,31c0,0-1.4,0.9-1.4,0.9l-2,1L77.2,33l0.4,0.8L77.9,33.7L77.9,33.7z"/>
				<path fill="#FFFFFF" d="M42.7,63.8L42.7,63.8c0,0,0.3-0.2,0.3-0.2l0.3-0.2c0.2-0.1,0.3-0.2,0.8,0.3l5.3,4.7l2.3,2.2
		c0,0,0.2,0.2,0.2,0.2c0.8,0.7,1.6,1.2,2.3,1.4c1.6,0.5,3.9-0.2,5.2-1.7c1.4-1.6,2.3-4.2,2.3-7c0-0.1,0-0.3,0-0.4
		c0.5,0.4,2.2,1.9,2.2,1.9l0.2-0.2c0,0,0.7-1.1,0.7-1.1c0,0,0.7-0.8,0.7-0.8c0,0,0.1-0.2,0.1-0.2l0-0.1l-0.2-1.2l-0.4,0.5
		c-0.3,0.3-0.5,0.5-0.7,0.6c-0.4-0.1-2.4-1.6-4.9-3.8l-0.9-0.8l-4.5-4.1L53,52.5c-0.1-0.2-0.1-0.2,0.3-0.9l0.1-0.1l0-0.2l-0.5-0.5
		L52.6,51c0,0-1.1,1.5-1.1,1.5l-1.6,1.5c0,0-0.1,0.2-0.1,0.2l-0.1,0.1l0.7,0.6l0.1-0.1l0.2-0.1c0.7-0.6,0.7-0.5,1-0.4
		c0,0,0.7,0.6,0.7,0.6c0,0,0.2,0.1,0.2,0.1c0,0,7.1,6.2,7.1,6.2c0,0,0.1,0.1,0.1,0.1c0.6,0.8,0.8,1.8,0.8,2.9c0,1.5-0.5,3.1-1.6,4.3
		c-0.6,0.7-1.5,1.2-2.5,1.5c-1.5,0.4-2.9-0.2-5.2-2.3l-0.6-0.5l-5.3-4.9c-0.5-0.4-0.6-0.8,0-1.6l0.1-0.1l-0.7-0.7l-0.3,0.3
		c0,0-1.1,1.4-1.1,1.4c0,0-1.4,1.3-1.4,1.4c0,0-0.3,0.3-0.3,0.3l0.7,0.6L42.7,63.8L42.7,63.8z"/>
				<path fill="#FFFFFF" d="M58.1,47.1c0.7-0.5,1-0.5,1.9-0.2c0,0,0.1,0,0.1,0c0,0.1,0.1,0.1,0.1,0.1c0.9,1,2.3,2.9,4.4,5.6l1,1.4
		c1.9,2.6,2.6,3.6,2.8,4.1c0,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.4-0.5,0.8c-0.1,0.1-0.2,0.2-0.2,0.2l-0.1,0.1l0.1,0.1l0.4,0.6l0.3-0.2
		c0,0,1.3-1.1,1.3-1.1l1.7-1.1c0,0,0.4-0.3,0.4-0.3l-0.6-0.8l-0.2,0.2c-0.7,0.5-0.9,0.5-1.4-0.1L68.2,55c0,0-1.6-2.1-1.6-2.1
		c0,0-0.1-0.2-0.1-0.2s-3.1-4.2-4-5.4c1.1,0.2,3.6,0.6,7.7,1.5c2.1,0.4,5.6,1,11.6,2.1l0.8,0.1l-0.4-0.6c0,0-4.2-5.3-4.2-5.3
		l-5.5-7.7c-0.4-0.5-0.4-0.6,0.3-1.3l0.2-0.1l0-0.2l-0.5-0.6L72,35.4c-0.8,0.8-1.1,1-1.2,1.1c0,0-1.8,1.1-1.8,1.1
		c0,0-0.4,0.3-0.4,0.3l0.7,0.7l0.3-0.2c0.8-0.6,1-0.6,1.9,0.7l1.3,1.6c0,0,0.2,0.2,0.2,0.2s4.7,6.5,5.2,7.2
		c-0.8-0.1-10.8-1.8-10.8-1.8l-7.1-1.7c0,0-0.4-0.1-0.4-0.1l-0.1,0.1l-0.1,0.1c0-0.1-0.5,0.4-0.5,0.4l-0.5,0.4c0,0-1.2,0.7-1.2,0.7
		l-0.3,0.2l0.6,0.8L58.1,47.1L58.1,47.1z"/>
				<path fill="#FFFFFF" d="M110.9,225.6c0,0-2.2,0.4-2.2,0.4c0,0-3.3,0.3-3.3,0.3l-0.3,0l-0.7,1.4l0.5,0c2.1-0.2,3.1-0.4,4.3-0.6
		c0,0.2-0.3,1.4-0.3,1.4l-1.9,8.9c-0.4,2-0.8,2.1-2.1,1.8l-0.5-0.1l-0.2,1l0.4,0.1c0,0,2.6,0.5,2.6,0.5c0,0,2.5,0.6,2.5,0.6l0.5,0.1
		l0.2-1l-0.6-0.1c-0.6-0.1-0.9-0.3-1-0.5c-0.1-0.1-0.1-0.3-0.1-0.6c0-0.3,0.1-0.8,0.2-1.4l0.1-0.2c0,0,1.8-8,1.8-8
		c0,0,0.1-0.3,0.1-0.3l0.5-2.1c0,0,0.2-0.7,0.2-0.7l0.2-0.6l0.1-0.4l-0.5-0.1C111.1,225.6,110.9,225.6,110.9,225.6L110.9,225.6z"/>
				<path fill="#FFFFFF" d="M122.9,234.1c1.8-0.9,2.5-1.9,2.7-3.3c0-0.1,0-0.2,0-0.3c0-0.7-0.3-1.4-0.7-2c-0.6-0.8-1.6-1.3-2.7-1.4
		c-2.7-0.3-5.2,1.5-5.5,3.9c0,0.1,0,0.3,0,0.4c0,1.2,0.6,2.2,1.6,2.9c-2,0.7-3.1,2.1-3.3,4c0,0.2,0,0.3,0,0.5c0,2.2,1.6,3.9,4.1,4.1
		c3.2,0.3,6.3-1.9,6.6-4.8c0-0.1,0-0.2,0-0.3C125.6,236.2,124.4,234.6,122.9,234.1L122.9,234.1z M117,237.8c0.1-1.5,1.2-2.6,2.5-2.9
		c0,0,0.2,0,0.2,0c2.6,0.4,3.9,1.5,3.9,3.4c0,0.1,0,0.3,0,0.4c-0.2,1.9-1.5,3.1-3.3,2.9C118.1,241.4,116.8,239.8,117,237.8
		L117,237.8z M123.6,231.1c0,0.4-0.1,0.7-0.3,1c-0.4,0.9-1.4,1.5-2.1,1.5c-1.5-0.1-2.6-1.4-2.6-2.9c0-0.1,0-0.2,0-0.3
		c0.1-0.8,0.4-1.4,0.9-1.8c0.4-0.4,1-0.5,1.8-0.5c0.7,0.1,1.4,0.4,1.8,1C123.5,229.7,123.7,230.4,123.6,231.1L123.6,231.1z"/>
				<path fill="#FFFFFF" d="M135,227c-1.6,0-3.3,0.8-4.5,2.1c-1.2,1.3-1.9,3-1.9,4.7c0.1,2.3,1.9,4,4.2,4c1.3,0,1.9-0.3,3.1-1.1
		c0.1-0.1,1.2-1.8,1.2-1.8l-0.3-0.3c-1,0.8-1.9,1.2-3,1.3c-2,0.1-3.5-1.3-3.6-3.2c-0.1-2.3,1.6-4.2,3.6-4.2c2.3-0.1,4.2,1.9,4.2,4.4
		c0.1,2.7-1.4,5.8-3.8,7.7c-1.6,1.3-2.2,1.6-4.5,2.5l0.1,0.4l1.4,0.1c0.1,0,0.3-0.1,0.3-0.1c1.5-0.7,2.8-1.4,3.8-2.1
		c2.9-2.1,4.8-5.9,4.7-9.3C140,228.9,137.9,226.9,135,227L135,227z"/>
				<path fill="#FFFFFF" d="M151,225.3c-5.3,1-8.7,5.4-7.7,10.2c0.4,2.2,1.6,4,3.3,5.2c1.7,1.1,3.8,1.5,5.9,1.1c2.5-0.5,4.7-1.9,6.1-3.9
		c1.3-2,1.8-4.4,1.4-6.8C159.1,226.7,155.6,224.5,151,225.3L151,225.3z M158,235.6c-0.7,2.8-2.2,4.3-4.8,4.8c-1.8,0.4-3.5,0-4.9-0.9
		c-1.6-1.1-2.7-2.9-3.1-5.1c-0.4-2-0.1-3.7,0.8-5c0.9-1.3,2.4-2.2,4.3-2.5c3.9-0.8,7.2,1.4,7.9,5.2
		C158.4,233.2,158.3,234.4,158,235.6L158,235.6z"/>
			</g>
		</svg>

	)
}


export default Emblem;
