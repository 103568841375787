import React from 'react'
const Logo = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 982 650">
            <g>
                <g>
                    <g>
                        <path fill="#040404" d="M423.7,282.1c0-4.1-1.1-7.3-3.2-9.4c-2.1-2.1-6-4.3-11.6-6.6c-10.2-3.9-17.5-8.4-22-13.6
				c-4.5-5.2-6.7-11.3-6.7-18.4c0-8.6,3-15.5,9.1-20.6c6.1-5.2,13.8-7.8,23.2-7.8c6.2,0,11.8,1.3,16.7,3.9
				c4.9,2.6,8.7,6.3,11.3,11.1c2.6,4.8,3.9,10.2,3.9,16.3h-20.5c0-4.8-1-8.4-3-10.9c-2-2.5-4.9-3.7-8.8-3.7c-3.6,0-6.4,1.1-8.4,3.2
				c-2,2.1-3,5-3,8.6c0,2.8,1.1,5.3,3.4,7.6c2.2,2.3,6.2,4.6,11.9,7c9.9,3.6,17.1,8,21.6,13.2c4.5,5.2,6.7,11.9,6.7,19.9
				c0,8.9-2.8,15.8-8.5,20.8c-5.6,5-13.3,7.5-23,7.5c-6.6,0-12.6-1.4-18-4.1c-5.4-2.7-9.6-6.6-12.7-11.6c-3.1-5-4.6-11-4.6-17.8
				h20.6c0,5.9,1.1,10.1,3.4,12.8c2.3,2.7,6,4,11.2,4C420.1,293.5,423.7,289.7,423.7,282.1z"/>
                        <path fill="#040404" d="M529.3,308.8h-20.5v-43.5h-30.3v43.5H458V207h20.5v41.2h30.3V207h20.5V308.8z" />
                        <path fill="#040404" d="M594.3,287.9h-28l-5.4,20.8h-21.7L570.9,207h18.8l32,101.7h-21.9L594.3,287.9z M570.8,270.8h19l-9.5-36.3
				L570.8,270.8z"/>
                        <path fill="#040404" d="M662,271.6h-10.2v37.2h-20.5V207h32.8c10.3,0,18.2,2.7,23.9,8c5.6,5.3,8.4,12.9,8.4,22.7
				c0,13.5-4.9,23-14.7,28.4l17.8,41.6v1h-22.1L662,271.6z M651.8,254.5h11.7c4.1,0,7.2-1.4,9.2-4.1c2-2.7,3.1-6.4,3.1-10.9
				c0-10.2-4-15.3-11.9-15.3h-12V254.5z"/>
                        <path fill="#040404" d="M763,264.8h-32v27h37.9v17h-58.4V207h58.3v17.1H731v24h32V264.8z" />
                        <path fill="#040404" d="M780.7,308.8V207h26.9c11.9,0,21.3,3.8,28.4,11.3c7.1,7.5,10.7,17.9,10.8,31v16.5c0,13.4-3.5,23.9-10.6,31.5
				c-7.1,7.6-16.8,11.4-29.2,11.4H780.7z M801.2,224.2v67.6h6.1c6.8,0,11.7-1.8,14.5-5.4c2.8-3.6,4.3-9.8,4.4-18.7v-17.7
				c0-9.5-1.3-16.1-4-19.9c-2.7-3.7-7.2-5.7-13.6-5.9H801.2z"/>
                    </g>
                    <g>
                        <g>
                            <path fill="#040404" d="M423.7,419c0-4.1-1.1-7.3-3.2-9.4c-2.1-2.1-6-4.3-11.6-6.6c-10.2-3.9-17.5-8.4-22-13.6
					c-4.5-5.2-6.7-11.3-6.7-18.4c0-8.6,3-15.5,9.1-20.6c6.1-5.2,13.8-7.8,23.2-7.8c6.2,0,11.8,1.3,16.7,3.9
					c4.9,2.6,8.7,6.3,11.3,11.1c2.6,4.8,3.9,10.2,3.9,16.3h-20.5c0-4.8-1-8.4-3-10.9c-2-2.5-4.9-3.7-8.8-3.7c-3.6,0-6.4,1.1-8.4,3.2
					c-2,2.1-3,5-3,8.6c0,2.8,1.1,5.3,3.4,7.6c2.2,2.3,6.2,4.6,11.9,7c9.9,3.6,17.1,8,21.6,13.2c4.5,5.2,6.7,11.9,6.7,19.9
					c0,8.9-2.8,15.8-8.5,20.8c-5.6,5-13.3,7.5-23,7.5c-6.6,0-12.6-1.4-18-4.1c-5.4-2.7-9.6-6.6-12.7-11.6c-3.1-5-4.6-11-4.6-17.8
					h20.6c0,5.9,1.1,10.1,3.4,12.8c2.3,2.7,6,4,11.2,4C420.1,430.4,423.7,426.6,423.7,419z"/>
                            <path fill="#040404" d="M523.8,361.1h-25.2v84.6h-20.6v-84.6h-24.7V344h70.5V361.1z" />
                            <path fill="#040404" d="M601.8,344v70.4c-0.1,10.6-3,18.7-8.7,24.3c-5.7,5.6-14,8.4-24.7,8.4c-10.9,0-19.2-2.8-25-8.5
					c-5.8-5.7-8.6-13.9-8.6-24.7V344h20.7v70c0,5.8,0.9,9.9,2.8,12.4c1.9,2.5,5.2,3.7,10.1,3.7c4.9,0,8.2-1.2,10.1-3.7
					c1.8-2.4,2.7-6.5,2.8-12.1V344H601.8z"/>
                            <path fill="#040404" d="M617.4,445.7V344h26.9c11.9,0,21.3,3.8,28.4,11.3c7.1,7.5,10.7,17.9,10.8,31v16.5
					c0,13.4-3.5,23.9-10.6,31.5c-7.1,7.6-16.8,11.4-29.2,11.4H617.4z M637.9,361.1v67.6h6.1c6.8,0,11.7-1.8,14.5-5.4
					c2.8-3.6,4.3-9.8,4.4-18.7v-17.7c0-9.5-1.3-16.1-4-19.9c-2.7-3.7-7.2-5.7-13.6-5.9H637.9z"/>
                            <path fill="#040404" d="M719.7,445.7h-20.5V344h20.5V445.7z" />
                            <path fill="#040404" d="M808.5,404c0,13.6-3.2,24.2-9.7,31.8c-6.5,7.5-15.4,11.3-26.9,11.3c-11.4,0-20.4-3.7-26.9-11.2
					c-6.5-7.5-9.8-17.9-9.9-31.4v-17.4c0-14,3.2-24.9,9.7-32.7c6.5-7.8,15.5-11.8,27-11.8c11.3,0,20.2,3.9,26.8,11.6
					c6.5,7.7,9.8,18.5,9.9,32.5V404z M787.9,387c0-9.2-1.3-16-3.9-20.5c-2.6-4.5-6.7-6.7-12.2-6.7c-5.4,0-9.5,2.2-12.1,6.5
					c-2.6,4.3-4,10.9-4.1,19.7V404c0,8.9,1.3,15.5,4,19.7c2.7,4.2,6.8,6.3,12.3,6.3c5.4,0,9.3-2.1,11.9-6.2c2.6-4.1,3.9-10.5,4-19.2
					V387z"/>
                            <path fill="#040404" d="M866.2,419c0-4.1-1.1-7.3-3.2-9.4c-2.1-2.1-6-4.3-11.6-6.6c-10.2-3.9-17.5-8.4-22-13.6
					c-4.5-5.2-6.7-11.3-6.7-18.4c0-8.6,3-15.5,9.1-20.6c6.1-5.2,13.8-7.8,23.2-7.8c6.2,0,11.8,1.3,16.7,3.9
					c4.9,2.6,8.7,6.3,11.3,11.1c2.6,4.8,3.9,10.2,3.9,16.3h-20.5c0-4.8-1-8.4-3-10.9c-2-2.5-4.9-3.7-8.8-3.7c-3.6,0-6.4,1.1-8.4,3.2
					c-2,2.1-3,5-3,8.6c0,2.8,1.1,5.3,3.4,7.6c2.2,2.3,6.2,4.6,11.9,7c9.9,3.6,17.1,8,21.6,13.2c4.5,5.2,6.7,11.9,6.7,19.9
					c0,8.9-2.8,15.8-8.5,20.8c-5.6,5-13.3,7.5-23,7.5c-6.6,0-12.6-1.4-18-4.1c-5.4-2.7-9.6-6.6-12.7-11.6c-3.1-5-4.6-11-4.6-17.8
					h20.6c0,5.9,1.1,10.1,3.4,12.8c2.3,2.7,6,4,11.2,4C862.7,430.4,866.2,426.6,866.2,419z"/>
                        </g>
                    </g>
                </g>
                <g>
                    <path fill="#040404" d="M100.4,103.3v207.1c0,0.6,0.5,1.1,1.1,1.1h225.7c0.6,0,1.1-0.5,1.1-1.1V206.1c0-0.4-0.3-0.8-0.7-1L102,102.3
			C101.3,102,100.4,102.5,100.4,103.3z"/>
                    <path fill="#040404" d="M100.4,505.2V345.1c0-0.6,0.5-1.1,1.1-1.1h225.7c0.6,0,1.1,0.5,1.1,1.1v104.4c0,0.4-0.3,0.8-0.7,1L102,506.3
			C101.3,506.6,100.4,506.1,100.4,505.2z"/>
                </g>
            </g>
        </svg>
    )
}
export default Logo;