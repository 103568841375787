import React, { useRef } from 'react';
import styles from './styles.module.css';
import AgendaItem from './agenda-item';
// import { useSpring } from 'react-spring';
import { shallowEqual, useSelector } from 'react-redux';

const Agenda = () => {
    const elem = useRef();
    // const [y, setY] = useSpring(() => ({ y: 0 }));
    const { agendas } = useSelector(({ agendas }) => {
        return { agendas: agendas.list }
    }, shallowEqual);

    const scrollTo = (offsetTop) => {
        // const to = offsetTop - elem.current.offsetTop
        // if (getComputedStyle(document.body).scrollBehavior !== 'smooth') {
        //     // setY({ y: to, onFrame: (props) => elem.current.scroll(y, props.y) })
        // } else {
        //     elem.current.scroll({ top: to, behavior: 'smooth' })
        // }
    }

    return (
        <div className={styles.agendas}>
            <div className={styles.header} tabIndex="28">Order of Ceremony</div>
            <div ref={elem} className={styles.agenda_list} tabIndex="29">
                {agendas.map((agenda, i) => {
                    return <AgendaItem key={i} {...agenda} scrollTo={scrollTo} />
                })}
            </div>
        </div>
    )
}

export default React.memo(Agenda)
