import React from 'react';
import styles from './styles.module.css';
import LandingPageHeader from './landing-page-header';
import LiveVideo from '../live-video';
import Viewers from '../viewers';
import Emojis from '../Emoji/emojis';
import Comments from '../comments';
import Agendas from '../agendas';
import Seal from './seal';
import PoweredBy from '../powered-by';
import SocialBoard from '../social-board';
import Footer from '../footer';

const LandingPage = () => {

    return (
        <div className={styles.account_page}>
            <div className={styles.rect}></div>
            <LandingPageHeader />
            <div className={styles.rect}></div>
            <div className={styles.account_body}>
                <LiveVideo />
                <Viewers />
                <Emojis />
                <Comments />
            </div>
            <div className={styles.section_two}>
                <Agendas />
                <SocialBoard />
                <Seal className={styles.seal} />
                <PoweredBy />
            </div>
            <Footer />
        </div >
    )
}

export default React.memo(LandingPage);