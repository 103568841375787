import React, { useState, useRef, useEffect } from 'react';
import styles from './styles.module.css'
import { useDispatch } from 'react-redux'
import { reactWithEmoji } from '../../actions/emoji'

const EmojiButton = ({ formatted, count, name, images }) => {
    const interval = useRef();
    const dispatch = useDispatch();
    const [index, setSelected] = useState(0);
    const [isMouseDown, setMouseDown] = useState(false);
    const [showOptions, setShowOptions] = useState(false);

    useEffect(() => {
        if (isMouseDown && images.length > 1) {
            interval.current = setInterval(() => {
                setShowOptions(true)
                clearInterval(interval.current)
            }, 600);
            return () => clearInterval(interval.current)
        }
    }, [isMouseDown, images.length]);

    const handleClick = () => {
        if (!showOptions) {
            dispatch(reactWithEmoji(name, count, images[index].name))
        }
    }

    const handleSelect = (i) => {
        setShowOptions(false);
        setMouseDown(false);
        setSelected(i);
    }

    return (
        <div className={styles.emoji_selector} onMouseLeave={() => setShowOptions(false)}>
            {console.log("showOptions", showOptions)}
            {showOptions && <div className={styles.emoji_options}>
                {images.map((image, i) => (
                    <button key={i} className={`${styles.emoji} ${styles.emoji_option}`} onClick={() => handleSelect(i)}>
                        <img className={styles.emoji_image} alt='emoji' src={image.src} />
                    </button >
                ))}
            </div>}
            <button
                className={styles.emoji}
                onClick={handleClick}
                onMouseDown={() => setMouseDown(true)}
                onMouseUp={() => setMouseDown(false)}
            >
                <img className={styles.emoji_image} alt='emoji' src={images[index].src} />
                {formatted}
            </button >
        </div>
    )
}

export default React.memo(EmojiButton)