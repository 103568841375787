import React from 'react';
import styles from './styles.module.css';


const HeaderTitle = () => {
    return (
        <div className={styles.header_title}>
            <p className={styles.title} tabIndex="3">Welcome!</p>
            <p className={styles.time} tabIndex="4">Saturday,May 14, 2022</p>
        </div>
    )
}

export default React.memo(HeaderTitle)