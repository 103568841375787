import React from 'react'
import styles from './styles.module.css'
import moment from 'moment'

const getTime = (time_stamp) => {
    const now = moment();
    const time = moment(time_stamp);
    const minutes = now.diff(time, 'minutes');
    if (minutes > 60) {
        return moment(time_stamp).format('h:mm a');
    } else {
        return moment(time_stamp).fromNow(true);
    }
}

const Comment = ({ time_stamp, first_name, last_name, message, approved }) => {
    const notApproved = (approved && approved !== 'approved');
    return (
        <div className={styles.comment} >
            <p className={styles.user_name}>
                {first_name} {last_name} · {notApproved ? <span>{approved}</span> : getTime(time_stamp)}
            </p>
            <p className={styles.message}>{message}</p>
        </div >
    );
}

export default React.memo(Comment);