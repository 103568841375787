// The Nature of Code
// Daniel Shiffman
// http://natureofcode.com
import p5 from 'p5';

export default class Particle {
  constructor(x, y, r, img, p) {
    this.mass = 1;
    this.position = p.createVector(x, y);
    this.velocity = p.createVector(p.random(-5, 5), p.random(-20, -10));
    this.acceleration = p.createVector(0, 0);
    this.img = img;
    this.r = r;
    this.p = p
  }

  applyForce(force) {
    var f = p5.Vector.div(force, this.mass);
    this.acceleration.add(f);
  }

  update() {
    this.velocity.add(this.acceleration);
    this.position.add(this.velocity);
    this.acceleration.mult(0);
  }

  display() {

    this.p.imageMode(this.p.CENTER);
    this.p.rectMode(this.p.CENTER);
    this.p.push();
    this.p.translate(this.position.x, this.position.y);
    this.p.stroke(200);
    this.p.strokeWeight(2);
    this.p.image(this.img, 0, 0, this.r * 2, this.r * 2);
    this.p.pop();

  }
  done() {
    // Let's find the screen position of the particle
    if (this.position.y > this.p.height + this.r * 2) {
      return true;
    }
    return false;
  }
}
