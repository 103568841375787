import React, { useRef, useEffect } from 'react';
import styles from './styles.module.css';

const AgendaItem = ({ title, status, scrollTo }) => {
    const elem = useRef();

    useEffect(() => {
        if (status === 'current') {
            scrollTo(elem.current.offsetTop - 10)
        }
    });

    return <div ref={elem} className={`${styles.agenda} ${styles[status]}`}>{title}</div>
}

export default React.memo(AgendaItem);
