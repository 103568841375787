import React from 'react';
import styles from './styles.module.css';
import Icon from '../icon';
import PostCard from './post-card';
import posts from './posts.json';

const SocialBoard = () => {
    const col1 = [], col2 = [], col3 = [];
    const screenWidth = window.innerWidth;
    let numberOfColumns = 3;

    if (screenWidth <= 600 && screenWidth > 425) numberOfColumns = 2;
    for (let i = 0; i < posts.length; i += numberOfColumns) {
        if (posts.indexOf(posts[i]) !== -1) col1.push(posts[i])
        if (posts.indexOf(posts[i + 1]) !== -1) col2.push(posts[i + 1])
        if (numberOfColumns >= 3) if (posts.indexOf(posts[i + 2]) !== -1) col3.push(posts[i + 2])
    }

    return (
        <div className={styles.social_board}>
            <div className={styles.title} tabIndex="30"><Icon className={styles.title_icon} icon="insta" /><p>Use #thisisduc to share your celebrations!</p></div>
            <div className={styles.posts} tabIndex="31">
                <div className={styles.column}>{col1.map((post, i) => <PostCard key={i} {...post} />)}</div>
                <div className={styles.column}>{col2.map((post, i) => <PostCard key={i} {...post} />)}</div>
                <div className={styles.column}>{col3.map((post, i) => <PostCard key={i} {...post} />)}</div>
            </div>
        </div>
    )
}

export default React.memo(SocialBoard)