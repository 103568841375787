import React from 'react';
import styles from './styles.module.css';
import Logo from '../../logo';
import HeaderTitle from './header-title';
import Socials from './socials';

const LandingPageHeader = () => {

    return (
        <div className={styles.header}>
            <Logo className={styles.logo} aria-label="Logo of Dominican University of California" tabIndex="2" />
            <div className={styles.header_rest}>
                <HeaderTitle />
                <Socials />
            </div>
        </div>
    )
}

export default React.memo(LandingPageHeader)