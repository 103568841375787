import React from "react"

const Logo = ({ className }) => {
    return (
        <svg className={className} viewBox="0 0 200 65" fill="none">
            <path d="M4.79833 8.74993C4.79833 6.38882 4.93741 4.09715 4.93741 1.59715C6.05007 1.52771 6.9541 1.45827 7.85814 1.45827C12.8651 1.45827 16.4812 5.06938 16.4812 10.2083C16.4812 15.6249 13.7691 18.9583 8.34492 18.9583C7.23227 18.9583 6.18915 18.8888 4.79833 18.7499V8.74993ZM0.278164 20.0694C1.66898 20.0694 3.05981 19.9305 4.52017 19.9305C5.91099 19.9305 7.30181 20.0694 8.76217 20.0694C16.7594 20.0694 20.4451 16.5277 20.4451 10.0694C20.4451 3.68049 15.7163 0.27771 7.99722 0.27771C6.67594 0.27771 5.35466 0.416599 4.03338 0.416599C2.64256 0.486043 1.32128 0.347154 0 0.347154V0.833266C1.04312 1.11104 1.46036 1.94438 1.46036 3.12493C1.46036 4.51382 1.73853 6.73604 1.73853 9.23604V17.4999C1.73853 18.7499 1.39082 19.236 0.278164 19.5138V20.0694Z" fill="white" />
            <path d="M30.6675 10.2083C30.6675 5.55556 32.8928 1.18056 37.7607 1.18056C42.559 1.18056 44.8539 5.55556 44.8539 10.2083C44.8539 14.8611 42.6285 19.2361 37.7607 19.2361C32.8928 19.2361 30.6675 14.8611 30.6675 10.2083ZM26.7732 10.2083C26.7732 16.7361 31.2238 20.4167 37.7607 20.4167C44.2975 20.4167 48.7482 16.7361 48.7482 10.2083C48.7482 3.68056 44.2975 0 37.7607 0C31.2238 0 26.7732 3.68056 26.7732 10.2083Z" fill="white" />
            <path d="M74.687 0H75.5215L76.7732 16.7361C76.9123 18.125 77.1905 19.4444 78.3727 19.5139V20.1389C77.3991 20.1389 76.4255 20 75.452 20C74.4784 20 73.5048 20.1389 72.5312 20.1389V19.5139C73.4353 19.375 73.783 18.4722 73.783 17.5694C73.783 16.6667 73.7134 16.0417 73.6439 15.625L72.8789 7.08333H72.8094L66.4116 20.2083L58.4144 6.73611L57.9276 15.1389C57.9276 15.5556 57.7885 16.3889 57.7885 17.0139C57.7885 18.4722 58.2058 19.2361 59.388 19.5139V20.1389C58.5535 20.1389 57.719 20 56.815 20C55.9805 20 55.146 20.1389 54.2419 20.1389V19.5139C55.4937 19.2361 55.8414 18.2639 56.05 16.1111L57.4408 0H58.4144L67.0375 15.3472L74.687 0Z" fill="white" />
            <path d="M89.7079 0.972168C88.6647 1.11106 88.5257 1.87495 88.5257 2.84717C88.5257 5.62495 88.3866 8.95828 88.3866 11.9444V17.4305C88.3866 18.3333 88.7343 19.4444 89.7774 19.4444V20.0694C88.8038 20.0694 87.8303 19.9305 86.8567 19.9305C85.8831 19.9305 84.9095 20.0694 83.936 20.0694V19.4444C84.84 19.3749 85.3268 18.6111 85.3268 17.4305V13.3333C85.3268 9.9305 85.0486 4.9305 85.0486 3.19439C85.0486 2.01383 84.9095 1.11106 83.7969 0.972168V0.347168C84.7704 0.347168 85.744 0.486057 86.7176 0.486057C87.6912 0.486057 88.6647 0.347168 89.6383 0.347168V0.972168H89.7079Z" fill="white" />
            <path d="M95.5493 19.5139C96.662 19.2361 97.2878 18.4028 97.2878 16.8056L97.4269 0H98.6091L112.448 15.2083V5.48611C112.448 1.94444 111.683 1.18056 110.292 0.972222V0.347222C111.196 0.347222 112.17 0.486111 113.074 0.486111C113.978 0.486111 114.951 0.347222 115.925 0.347222V0.972222C114.673 1.18056 114.047 2.15278 114.047 4.51389L113.978 20.4861H113.004L98.9568 5.13889L99.0959 15.9028C99.0959 18.125 99.7913 19.0972 101.182 19.4444V20.0694C100.278 20.0694 99.3045 19.9306 98.4005 19.9306C97.4965 19.9306 96.5924 20.0694 95.6189 20.0694V19.5139H95.5493Z" fill="white" />
            <path d="M128.234 0.972168C127.19 1.11106 127.051 1.87495 127.051 2.84717C127.051 5.62495 126.912 8.95828 126.912 11.9444V17.4305C126.912 18.3333 127.19 19.4444 128.303 19.4444V20.0694C127.33 20.0694 126.356 19.9305 125.382 19.9305C124.409 19.9305 123.435 20.0694 122.462 20.0694V19.4444C123.366 19.3749 123.853 18.6111 123.853 17.4305V13.3333C123.853 9.9305 123.574 4.9305 123.574 3.19439C123.574 2.01383 123.435 1.11106 122.323 0.972168V0.347168C123.296 0.347168 124.27 0.486057 125.243 0.486057C126.217 0.486057 127.191 0.347168 128.164 0.347168V0.972168H128.234Z" fill="white" />
            <path d="M152.086 4.93056H150.904C150 2.29167 147.218 1.25 144.784 1.25C139.847 1.25 137.83 5.20833 137.83 10C137.83 17.9861 143.602 19.375 145.967 19.375C148.261 19.375 150.348 18.4028 151.599 16.3889L152.503 16.7361L151.599 19.8611C149.305 20.2778 147.914 20.4861 146.106 20.4861C137.691 20.4861 133.936 15.2778 133.936 10.2083C133.866 4.30556 138.387 0 144.784 0C148.47 0 149.861 0.625 151.878 1.11111L152.086 4.93056Z" fill="white" />
            <path d="M162.448 12.9861L165.23 5.55556L168.081 12.9861H162.448ZM168.567 14.375L169.541 17.0139C169.75 17.5694 169.889 18.0556 169.889 18.4722C169.889 19.0972 169.541 19.5139 168.985 19.5139V20.1389C169.958 20.1389 170.862 20 171.836 20C172.81 20 173.714 20.1389 174.687 20.1389V19.5139C173.644 19.375 173.435 18.4722 172.392 15.9722L166.203 0H165.855L159.805 15.2083C159.249 16.5278 158.206 19.2361 157.024 19.4444V20.0694C157.858 20.0694 158.623 19.9306 159.458 19.9306C160.292 19.9306 161.057 20.0694 161.892 20.0694V19.4444C161.196 19.4444 160.848 18.8889 160.848 18.1944C160.848 17.9167 160.918 17.2222 161.057 16.8056L161.822 14.3056H168.567V14.375Z" fill="white" />
            <path d="M179.625 19.5139C180.737 19.2361 181.363 18.4028 181.363 16.8056L181.433 0H182.615L196.453 15.2083V5.48611C196.453 1.94444 195.688 1.18056 194.298 0.972222V0.347222C195.202 0.347222 196.175 0.486111 197.079 0.486111C197.983 0.486111 198.957 0.347222 199.93 0.347222V0.972222C198.679 1.18056 198.053 2.15278 198.053 4.51389L197.983 20.4861H197.01L183.032 5.13889L183.171 15.9028C183.171 18.125 183.867 19.0972 185.257 19.4444V20.0694C184.353 20.0694 183.38 19.9306 182.476 19.9306C181.572 19.9306 180.668 20.0694 179.694 20.0694V19.5139H179.625Z" fill="white" />
            <path d="M0.208662 25.9027C1.25178 25.9027 2.22535 26.0416 3.26847 26.0416C4.31158 26.0416 5.3547 25.9027 6.32827 25.9027V26.5277C5.07653 26.6666 4.72883 27.5694 4.72883 29.3749C4.72883 32.0833 4.72883 34.7916 4.72883 37.4305C4.72883 41.2499 6.46735 43.8888 10.7094 43.8888C13.0042 43.8888 15.9249 42.5694 15.9249 39.3749V36.0416C15.9249 32.7083 15.6468 29.3749 15.6468 27.9166C15.6468 27.1527 14.8818 26.5972 14.1864 26.5277V25.9027C15.2295 25.9027 16.2031 26.0416 17.2462 26.0416C18.2893 26.0416 19.3324 25.9027 20.306 25.9027V26.5277C19.402 26.5277 18.9847 27.6388 18.9847 28.611C18.9847 29.7222 18.9152 32.6388 18.9152 36.3888V42.5694C18.9152 44.1666 19.2629 44.9999 20.4451 45.0694V45.6944C19.6801 45.6944 18.8457 45.5555 18.0112 45.5555C17.2462 45.5555 16.4117 45.6944 15.6468 45.6944C15.7163 44.7222 15.7859 43.8888 15.8554 42.986C13.7692 44.9305 12.0306 46.0416 9.10991 46.0416C4.93745 46.0416 1.59948 43.611 1.59948 39.3749V34.6527C1.59948 32.4305 1.4604 30.486 1.4604 28.7499C1.4604 27.6388 1.25178 26.736 0.0695801 26.5972V25.9027H0.208662Z" fill="white" />
            <path d="M27.26 45C28.3727 44.7222 28.9985 43.8889 28.9985 42.2916L29.0681 25.5555H30.2503L44.0889 40.7639V31.0417C44.0889 27.5 43.324 26.7361 41.9332 26.5278V25.9028C42.8372 25.9028 43.8108 26.0417 44.7148 26.0417C45.6188 26.0417 46.5924 25.9028 47.566 25.9028V26.5278C46.3143 26.7361 45.6884 27.7778 45.6884 30.0694L45.6188 46.0416H44.6453L30.6675 30.6944L30.8066 41.4583C30.8066 43.6805 31.502 44.7222 32.8928 45V45.625C31.9888 45.625 31.0152 45.4861 30.1112 45.4861C29.2072 45.4861 28.2336 45.625 27.3295 45.625V45H27.26Z" fill="white" />
            <path d="M59.249 26.5277C58.2059 26.6666 58.0668 27.4305 58.0668 28.4027C58.0668 31.1805 57.9277 34.5138 57.9277 37.4999V42.986C57.9277 43.8888 58.2754 44.9999 59.3186 44.9999V45.6249C58.345 45.6249 57.3714 45.486 56.3978 45.486C55.4242 45.486 54.4507 45.6249 53.4771 45.6249V44.9999C54.3811 44.9999 54.8679 44.1666 54.8679 42.986V38.8888C54.8679 35.486 54.5898 30.486 54.5898 28.7499C54.5898 27.5694 54.4507 26.6666 53.338 26.5277V25.9027C54.3116 25.9027 55.2852 26.0416 56.2587 26.0416C57.2323 26.0416 58.2059 25.9027 59.1795 25.9027V26.5277H59.249Z" fill="white" />
            <path d="M73.6439 45.9721L65.8553 28.8194C65.5771 28.1249 64.8817 26.9444 63.8386 26.5277V25.9027C64.8817 25.9027 65.9944 26.0416 67.0375 26.0416C68.0806 26.0416 69.1933 25.9027 70.2364 25.9027V26.5277C69.6801 26.6666 69.1933 26.8749 69.1933 27.5694C69.1933 27.8472 69.2628 28.3333 69.6801 29.3055L74.8956 41.6666L79.3463 30.1388C79.694 29.3749 79.7635 28.6805 79.7635 28.2638C79.7635 27.1527 79.4158 26.736 78.3032 26.5277V25.9027C79.2767 25.9027 80.2503 26.0416 81.2239 26.0416C82.1975 26.0416 83.171 25.9027 84.1446 25.9027V26.5277C82.9624 26.6666 82.3365 27.6388 81.7802 29.0972L75.0347 46.0416H73.6439V45.9721Z" fill="white" />
            <path d="M88.7344 44.9305C89.847 44.7916 90.4034 44.1666 90.4034 43.1944V38.4027C90.4034 34.5833 90.1947 30.4861 90.1947 27.9861C90.1947 27.2916 89.6384 26.8055 88.8039 26.5972V25.9722H100C100.765 25.9722 101.739 25.6944 102.225 25.4861C102.225 26.25 102.086 26.875 102.086 27.6389C102.086 28.3333 102.225 29.0277 102.225 29.7222H101.113C100.626 28.3333 99.9305 27.2916 98.4006 27.2916H93.4632V34.8611H97.7747C99.4437 34.8611 99.9305 34.7222 100.626 33.3333H101.53C101.53 34.1666 101.391 35 101.391 35.7639C101.391 36.5972 101.53 37.4305 101.53 38.2639H100.556C99.9305 36.3194 99.3742 36.1805 97.7747 36.1805H93.4632V44.0972H98.2615C100.348 44.0972 101.113 43.75 102.434 41.9444L103.338 42.3611L102.364 45.8333C101.46 45.4861 100.626 45.4166 99.7219 45.4166H88.8735V44.9305H88.7344Z" fill="white" />
            <path d="M113.7 27.7084C113.7 27.0139 114.464 26.875 115.229 26.875C118.289 26.875 119.541 29.2362 119.541 31.8056C119.541 34.5139 117.316 36.25 114.673 36.25C114.325 36.25 113.978 36.1806 113.56 36.1806L113.7 27.7084ZM109.11 45.625C110.153 45.625 111.127 45.4861 112.1 45.4861C113.143 45.4861 114.117 45.625 115.16 45.625V45C113.7 44.6528 113.56 43.8195 113.56 42.6389V37.0139C114.256 37.0139 114.812 37.7778 115.229 38.2639L119.958 43.75C120.306 44.1667 120.375 44.375 120.375 44.6528C120.375 44.8611 120.236 44.9306 120.097 45V45.625C121.001 45.625 121.975 45.4861 122.879 45.4861C123.783 45.4861 124.757 45.625 125.73 45.625V45C124.965 44.8611 123.992 43.9584 123.574 43.4028L117.872 36.8056C120.375 36.1806 123.018 34.3056 123.018 31.4584C123.018 28.4028 120.515 25.8334 115.438 25.8334C113.282 25.8334 112.448 25.9723 111.613 25.9723C110.709 25.9723 109.805 25.8334 108.832 25.8334V26.4584C110.292 26.7362 110.431 27.4306 110.431 28.75C110.431 31.1806 110.57 33.6112 110.57 36.0417V43.3334C110.57 44.3056 110.153 44.7223 109.179 45V45.625H109.11Z" fill="white" />
            <path d="M142.559 29.7222H141.446C140.681 27.5694 138.943 26.7361 136.787 26.7361C134.84 26.7361 133.101 28.2639 133.101 30.2777C133.101 36.25 142.768 32.5694 142.768 40.2778C142.768 43.8889 139.569 46.0416 136.231 46.0416C134.353 46.0416 132.128 45.3472 131.154 44.7222L130.876 41.6666H131.919C132.337 43.5416 134.353 44.7916 136.231 44.7916C138.387 44.7916 140.056 43.4028 140.056 41.1805C140.056 36.1805 130.389 39.0972 130.389 31.6666C130.389 27.9166 133.449 25.4861 136.996 25.4861C138.873 25.4861 140.821 25.6944 142.559 26.5972V29.7222Z" fill="white" />
            <path d="M155.007 26.5277C153.964 26.6666 153.825 27.4305 153.825 28.4027C153.825 31.1805 153.686 34.5138 153.686 37.4999V42.986C153.686 43.8888 153.964 44.9999 155.076 44.9999V45.6249C154.103 45.6249 153.129 45.486 152.156 45.486C151.182 45.486 150.209 45.6249 149.235 45.6249V44.9999C150.139 44.9999 150.626 44.1666 150.626 42.986V38.8888C150.626 35.486 150.348 30.486 150.348 28.7499C150.348 27.5694 150.209 26.6666 149.096 26.5277V25.9027C150.07 25.9027 151.043 26.0416 152.017 26.0416C152.99 26.0416 153.964 25.9027 154.937 25.9027V26.5277H155.007Z" fill="white" />
            <path d="M178.999 29.5139H177.886C177.608 27.8472 176.773 27.3611 175.104 27.3611H171.558C171.558 29.7916 171.419 32.9166 171.419 36.3194V42.3611C171.419 44.0972 171.975 44.6528 173.227 45.0694V45.6944C172.114 45.6944 171.071 45.5555 169.958 45.5555C168.915 45.5555 167.802 45.6944 166.69 45.6944V45.0694C168.011 44.6528 168.359 44.0278 168.359 42.3611V37.9166C168.359 33.8194 168.15 29.7916 168.081 27.3611H164.256C162.656 27.3611 161.822 27.8472 161.474 29.5139H160.431V25.4861C161.474 25.7639 162.587 25.9722 163.7 25.9722H176.217C176.982 25.9722 178.234 25.8333 178.929 25.4861V29.5139H178.999Z" fill="white" />
            <path d="M187.9 45C189.291 44.7917 189.638 44.0973 189.638 42.7084V37.2917L184.353 28.4723C183.797 27.5695 183.519 27.0139 182.684 26.4584V25.8334C183.658 25.8334 184.631 25.9723 185.605 25.9723C186.579 25.9723 187.552 25.8334 188.526 25.8334V26.4584C187.969 26.5972 187.622 26.875 187.622 27.4306C187.622 27.7084 187.761 28.5417 188.178 29.2361L191.794 35.5556L195.341 29.4445C195.897 28.5417 195.967 27.8472 195.967 27.2917C195.967 26.6667 195.341 26.3889 194.784 26.3889V25.7639C195.619 25.7639 196.453 25.9028 197.357 25.9028C198.192 25.9028 199.026 25.7639 199.93 25.7639V26.3889C199.026 26.7361 198.47 27.5 197.705 28.75L192.768 36.8056V42.6389C192.768 44.0278 193.185 44.6528 194.437 44.9306V45.5556C193.324 45.5556 192.281 45.4167 191.238 45.4167C190.195 45.4167 189.082 45.5556 187.969 45.5556V45H187.9Z" fill="white" />
            <path d="M65.5772 53.8195H64.9514C64.4646 52.4306 63.0738 51.9445 61.822 51.9445C59.249 51.9445 58.2059 53.9584 58.2059 56.4584C58.2059 60.5556 61.1962 61.25 62.3784 61.25C63.5606 61.25 64.6037 60.7639 65.2991 59.7222L65.7163 59.9306L65.2991 61.5278C64.1169 61.7361 63.4215 61.875 62.4479 61.875C58.0668 61.875 56.1892 59.1667 56.1892 56.5972C56.1892 53.6111 58.4841 51.3889 61.7525 51.3889C63.6301 51.3889 64.395 51.7361 65.4382 51.9445L65.5772 53.8195Z" fill="white" />
            <path d="M71.3491 57.9861L72.7399 54.1667L74.2003 57.9861H71.3491ZM74.4785 58.6806L74.9652 60.0695C75.0348 60.3472 75.1739 60.625 75.1739 60.8333C75.1739 61.1806 74.9652 61.3889 74.6871 61.3889V61.7361C75.1739 61.7361 75.6606 61.6667 76.1474 61.6667C76.6342 61.6667 77.121 61.7361 77.6078 61.7361V61.3889C77.0515 61.3195 76.9819 60.8333 76.4256 59.5139L73.2267 51.3195H73.0181L69.8887 59.1667C69.6106 59.8611 69.0543 61.25 68.4979 61.3889V61.7361C68.9152 61.7361 69.3324 61.6667 69.7497 61.6667C70.1669 61.6667 70.5842 61.7361 71.0014 61.7361V61.3889C70.6537 61.3889 70.4451 61.1111 70.4451 60.7639C70.4451 60.625 70.4451 60.2778 70.5842 60.0695L71.0014 58.75H74.4785V58.6806Z" fill="white" />
            <path d="M80.3894 61.3195C80.9457 61.3195 81.363 60.9028 81.363 60.4167V52.9861C81.363 52.2917 81.0153 51.875 80.4589 51.7361V51.3889C81.0153 51.3889 81.5716 51.4584 82.1279 51.4584C82.6843 51.4584 83.2406 51.3889 83.7969 51.3889V51.7361C83.171 51.875 83.032 52.2223 83.032 52.9861C83.032 53.4028 82.9624 54.375 82.9624 55.6945V60.7639C83.3101 60.8334 83.5883 60.8334 84.4923 60.8334C86.9958 60.8334 87.4826 60.3472 87.8303 59.4445L88.3171 59.7222L87.6912 61.8056C87.2044 61.6667 86.7872 61.5972 86.3004 61.5972H81.5021C81.1544 61.5972 80.7371 61.6667 80.3894 61.6667V61.3195Z" fill="white" />
            <path d="M94.2281 51.7361C93.6718 51.8056 93.6022 52.2223 93.6022 52.7084C93.6022 54.0972 93.5327 55.8334 93.5327 57.3611V60.2084C93.5327 60.6945 93.6718 61.25 94.2281 61.25V61.5972C93.7413 61.5972 93.185 61.5278 92.6982 61.5278C92.2114 61.5278 91.6551 61.5972 91.1683 61.5972V61.25C91.6551 61.25 91.8637 60.8334 91.8637 60.2084V58.125C91.8637 56.3889 91.7246 53.8195 91.7246 52.9167C91.7246 52.2917 91.6551 51.8056 91.0988 51.7361V51.3889C91.5855 51.3889 92.0723 51.4584 92.6287 51.4584C93.1154 51.4584 93.6022 51.3889 94.1586 51.3889V51.7361H94.2281Z" fill="white" />
            <path d="M97.7052 51.5278H103.547C103.894 51.5278 104.173 51.4583 104.52 51.3195C104.52 51.7361 104.52 52.0833 104.52 52.3611C104.52 52.6389 104.52 52.9167 104.52 53.2639H103.964C103.755 52.5695 103.268 52.2222 102.434 52.2222H100.348C100.348 53.4028 100.348 54.7222 100.278 56.1111H102.295C102.851 56.1111 103.408 55.9722 103.477 55.3472H103.964C103.964 55.7639 103.964 56.1806 103.964 56.5972C103.964 56.9445 103.964 57.3611 103.964 57.7083H103.477C103.338 57.0139 102.851 56.8056 102.295 56.8056H100.278C100.278 57.9861 100.278 59.2361 100.278 60.4861C100.278 60.9722 100.626 61.3195 101.182 61.3195V61.6667C100.626 61.6667 100.07 61.5972 99.5133 61.5972C98.9569 61.5972 98.4006 61.6667 97.9138 61.6667V61.3195C98.4702 61.25 98.7483 60.9722 98.7483 60.3472V57.4306C98.7483 55.6945 98.5397 53.5417 98.5397 52.6389C98.5397 52.1528 98.192 51.9445 97.7052 51.8056V51.5278Z" fill="white" />
            <path d="M109.527 56.5278C109.527 54.0972 110.709 51.875 113.143 51.875C115.647 51.875 116.759 54.0972 116.759 56.5278C116.759 58.9583 115.577 61.1805 113.143 61.1805C110.709 61.1805 109.527 58.9583 109.527 56.5278ZM107.51 56.5278C107.51 59.9306 109.805 61.8055 113.213 61.8055C116.62 61.8055 118.915 59.9306 118.915 56.5278C118.915 53.1944 116.62 51.25 113.213 51.25C109.805 51.25 107.51 53.1944 107.51 56.5278Z" fill="white" />
            <path d="M124.27 52.3611C124.27 52.0139 124.687 51.9445 125.035 51.9445C126.634 51.9445 127.26 53.125 127.26 54.5139C127.26 55.9028 126.078 56.8056 124.757 56.8056C124.548 56.8056 124.409 56.7361 124.2 56.7361L124.27 52.3611ZM121.905 61.5972C122.392 61.5972 122.949 61.5278 123.435 61.5278C123.992 61.5278 124.478 61.5972 125.035 61.5972V61.25C124.27 61.1111 124.2 60.625 124.2 60.0695V57.1528C124.548 57.1528 124.826 57.5695 125.104 57.7778L127.538 60.625C127.747 60.8334 127.747 60.9722 127.747 61.1111C127.747 61.25 127.677 61.25 127.608 61.3195V61.6667C128.095 61.6667 128.581 61.5972 129.068 61.5972C129.555 61.5972 130.042 61.6667 130.528 61.6667V61.3195C130.111 61.25 129.624 60.7639 129.416 60.4861L126.495 57.0834C127.816 56.7361 129.138 55.8334 129.138 54.3056C129.138 52.7084 127.816 51.3889 125.243 51.3889C124.131 51.3889 123.713 51.4584 123.296 51.4584C122.809 51.4584 122.323 51.3889 121.905 51.3889V51.7361C122.67 51.875 122.74 52.2222 122.74 52.9167C122.74 54.1667 122.809 55.4167 122.809 56.6667V60.4167C122.809 60.9028 122.601 61.1111 122.114 61.25V61.5972H121.905Z" fill="white" />
            <path d="M133.171 61.3195C133.727 61.1806 134.075 60.7639 134.075 59.9306L134.145 51.3195H134.771L141.864 59.0972V54.0972C141.864 52.2917 141.446 51.875 140.751 51.7361V51.3889C141.238 51.3889 141.725 51.4583 142.211 51.4583C142.698 51.4583 143.185 51.3889 143.672 51.3889V51.7361C143.046 51.875 142.698 52.3611 142.698 53.5417V61.8056H142.211L134.91 53.8889L134.979 59.4445C134.979 60.5556 135.327 61.1111 136.022 61.3195V61.6667C135.536 61.6667 135.049 61.5972 134.562 61.5972C134.075 61.5972 133.588 61.6667 133.102 61.6667V61.3195H133.171Z" fill="white" />
            <path d="M150.139 51.7361C149.583 51.8056 149.513 52.2223 149.513 52.7084C149.513 54.0972 149.444 55.8334 149.444 57.3611V60.2084C149.444 60.6945 149.583 61.25 150.139 61.25V61.5972C149.652 61.5972 149.166 61.5278 148.609 61.5278C148.122 61.5278 147.566 61.5972 147.079 61.5972V61.25C147.566 61.25 147.844 60.8334 147.844 60.2084V58.125C147.844 56.3889 147.705 53.8195 147.705 52.9167C147.705 52.2917 147.636 51.8056 147.079 51.7361V51.3889C147.566 51.3889 148.053 51.4584 148.609 51.4584C149.096 51.4584 149.583 51.3889 150.139 51.3889V51.7361Z" fill="white" />
            <path d="M156.12 57.9861L157.51 54.1667L158.971 57.9861H156.12ZM159.249 58.6806L159.736 60.0695C159.805 60.3472 159.875 60.625 159.875 60.8333C159.875 61.1806 159.736 61.3889 159.388 61.3889V61.7361C159.875 61.7361 160.362 61.6667 160.848 61.6667C161.335 61.6667 161.822 61.7361 162.309 61.7361V61.3889C161.752 61.3195 161.683 60.8333 161.127 59.5139L157.928 51.3195H157.858L154.729 59.1667C154.451 59.8611 153.894 61.25 153.338 61.3889V61.7361C153.755 61.7361 154.173 61.6667 154.59 61.6667C155.007 61.6667 155.424 61.7361 155.841 61.7361V61.3889C155.494 61.3889 155.285 61.1111 155.285 60.7639C155.285 60.625 155.285 60.2778 155.355 60.0695L155.772 58.75H159.249V58.6806Z" fill="white" />
            <path d="M39.8471 58.6806C39.8471 57.7084 40.4729 55.2778 41.5856 55.2778C42.6287 55.2778 43.046 56.4584 43.046 57.4306C43.046 58.4028 42.4896 60.8334 41.377 60.8334C40.2643 60.9028 39.8471 59.6528 39.8471 58.6806ZM38.804 58.8889C38.804 60.4862 39.5689 61.5973 40.6816 61.5973C42.6287 61.5973 44.0195 59.1667 44.0195 57.2917C44.0195 55.6945 43.2546 54.5834 42.1419 54.5834C40.1948 54.5834 38.804 57.0139 38.804 58.8889Z" fill="white" />
            <path d="M43.8804 64.0973C44.2281 64.1667 44.5062 64.2362 44.9235 64.2362C46.4534 64.2362 46.9402 63.6112 47.0793 62.0834C47.427 59.3056 47.4965 57.4306 47.7051 55.0695H46.8011L46.8706 54.6528H47.7051C47.9833 52.7778 48.0528 48.9584 50.6954 48.9584C50.904 48.9584 51.1822 49.0278 51.5299 49.1667V50.4167C51.2517 50.0695 51.0431 49.7223 50.3477 49.7223C49.5132 49.7223 49.3046 49.9306 48.9569 54.7223H50.2086L50.0695 55.1389H48.8178C48.7482 56.1806 48.6092 57.5695 48.4701 59.4445C48.331 61.8056 47.5661 65 44.7149 65H44.3672L43.8804 64.0973Z" fill="white" />
        </svg>
    )
}


export default Logo;
